<template>
  <div class="alert-wrap">
    <v-alert
      :value="alertState.show"
      :type="alertState.type"
      transition="slide-y-transition"
      dense
    >
      {{ alertState.msg }}
      <v-icon
        color="white"
        class="close-icon"
        @click="alertCng({ ...alertState, show: false })"
      >
        mdi-close
      </v-icon>
    </v-alert>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'DlgAlert',
  computed: {
    ...mapGetters({
      alertState: 'dialog/alert',
    }),
  },
  methods: {
    ...mapMutations({
      alertCng: 'dialog/alert',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
  },
};
</script>

<style scoped lang="scss">
  .alert-wrap {
    height: 0px;
    z-index: 500;
    width: 100vw;
    position: fixed;
    top:30px;
    background-color: rgba(0,0,0,0);
    display: flex;
    align-items: center;
    justify-content: center;
    .v-alert {
      margin-top:10px;
      box-shadow: 0px 2px 8px rgba(0,0,0,0.1) !important;
      font-weight: 500;
      margin-bottom:0px;
      .close-icon {
        margin-left: 20px;
        opacity: .8;
        cursor: pointer;
        margin-top:-3px;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
</style>
