import Vue from 'vue';
import SocketIO from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';
import VueClipboard from 'vue-clipboard2';
import store from '../store';
import path from '../util/path';

const connection = SocketIO(path.apiHost, {
  reconnectionDelayMax: 1000,
  reconnectionAttempts: 3,
  transports: ['websocket'],
});

connection.io.on('reconnect_failed', async () => {
  await store.dispatch('auth/socket.reconnect_failed');

  setTimeout(() => {
    connection.connect();
  }, 10000);
});

Vue.use(new VueSocketIO({
  debug: !(process.env.NODE_ENV === 'production'),
  connection,
  vuex: {
    store,
    actionPrefix: 'socket.',
    mutationPrefix: 'socket.',
  },
  options: {
    transports: ['websocket'],
    secure: true,
  },
}));
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
