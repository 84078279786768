<template>
  <v-dialog
    scrollable
    v-model="show"
    fullscreen
    persistent
    content-class="width-fix-dialog"
    v-if="item !== null"
    no-click-animation
  >
    <v-card class="content-card rounded-0">
      <div class="title-bar">
        <div class="title-info">
          <span
            class="error--text mr-2 pr-2 pl-2"
            v-if="item.disabled === 1"
          >
            <v-icon color="error">mdi-alert-circle-outline</v-icon>
            삭제된 {{ item.type === 0 ? '매출' : '환불' }}건 입니다.
          </span>
          <v-chip
            v-if="item.disabled !== 1"
            label
            :color="item.type === 0 ? 'success' : 'error' ">
            {{ item.type === 0 ? '매출' : '환불' }}
          </v-chip>
          <v-menu
            offset-y
            v-if="item.disabled !== 1 && checkFuncPermission('status')"
          >
            <template #activator="{ on, attrs }">
              <v-chip
                v-if="item.status === 0"
                class="ml-2"
                label
                v-bind="attrs"
                v-on="on"
              >
                미승인
              </v-chip>
              <v-chip
                v-else-if="item.status === 1"
                class="ml-2"
                label
                outlined
                v-bind="attrs"
                v-on="on"
              >
                {{ time.makeLocalTime(item.date, 'date') }}
              </v-chip>
              <v-chip
                v-else-if="item.status === 2 && item.type === 0"
                class="ml-2"
                label
                outlined
                v-bind="attrs"
                v-on="on"
                color="error"
              >
                가결제
              </v-chip>
            </template>
            <!-- 매출 상태변경 -->
            <v-list v-if="item.type === 0">
              <v-list-item
                class="options"
                @click="confirmDlgOpen(1)"
                v-if="item.status === 0 || item.status === 2"
              >매출 승인</v-list-item>
              <v-list-item
                class="options"
                @click="updateSalesStatus(2)"
                v-if="item.status !== 2 && item.productType === 0"
              >가결제 처리</v-list-item>
              <v-list-item
                class="options"
                @click="updateSalesStatus(0)"
                v-if="item.status === 2"
              >미승인으로 변경</v-list-item>
              <v-list-item
                v-if="item.status === 1"
                class="options"
                @click="confirmDlgOpen()"
              >매출일 변경</v-list-item>
              <v-list-item
                class="options"
                @click="updateSalesStatus(0)"
                v-if="item.status === 1"
              >승인 취소</v-list-item>
              <v-list-item
                class="options"
                @click="getHistories(
                  'sales.update',
                  'status',
                  '매출승인이력'
                )"
              >
                매출승인이력
              </v-list-item>
              <v-list-item
                v-if="item.status === 0"
                class="options error--text"
                @click="updateDisabled(item.no)"
              >
                삭제
              </v-list-item>
            </v-list>
            <!-- 상태변경: 환불 -->
            <v-list v-if="item.type === 1">
              <v-list-item
                class="options"
                @click="confirmDlgOpen(1)"
                v-if="item.status === 0 || item.status === 2"
              >환불 승인</v-list-item>
              <v-list-item
                class="options"
                @click="updateRefundStatus(0)"
                v-if="item.status === 2"
              >미승인으로 변경</v-list-item>
              <v-list-item
                v-if="item.status === 1"
                class="options"
                @click="confirmDlgOpen()"
              >환불일 변경</v-list-item>
              <v-list-item
                class="options"
                @click="updateRefundStatus(0)"
                v-if="item.status === 1"
              >승인 취소</v-list-item>
              <v-list-item
                class="options"
                @click="getHistories(
                  'sales.update',
                  'status',
                  '환불승인이력',
                  item,
                )"
              >
                환불승인이력
              </v-list-item>
              <v-list-item
                v-if="item.status === 0"
                class="options error--text"
                @click="updateDisabled(item.no)"
              >
                삭제
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-else-if="!checkFuncPermission('status')">
            <v-chip
              v-if="item.status === 0"
              class="ml-2"
              label
            >
              미승인
            </v-chip>
            <v-chip
              v-else-if="item.status === 1"
              class="ml-2"
              label
              outlined
            >
              {{ time.makeLocalTime(item.date, 'date') }}
            </v-chip>
            <v-chip
              v-else-if="item.status === 2 && item.type === 0"
              class="ml-2"
              label
              outlined
              color="error"
            >
              가결제
            </v-chip>
          </div>
          <p class="sales-number">
            {{ item.type === 0 ? '매출' : '환불' }}번호: {{ item.no }}
          </p>
        </div>
        <div class="item-wrap d-flex">
          <div class="text-item mr-5">
            <v-chip
              outlined
              label
              class="mr-2 pl-2 pr-2"
              small
            >등록</v-chip>
            <span class="item-span">
              {{
                `${comm.getGroupFullPath(groups, item.creatorGroupNo)} ${item.creatorName}`
              }}
            </span>
            <span class="item-span date">{{ time.makeLocalTime(item.created) }}</span>
          </div>
          <v-btn
            icon
            @click="init"
            color="black"
            large
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <!-- 매출인 경우 시작 -->
      <div
        class="content-wrap"
        v-if="item.type === 0"
      >
        <!-- 고객정보 시작 -->
        <div class="content-row">
          <p class="content-tit mr-4">
            판매정보
          </p>
          <div class="text-item mr-4">
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >고객</v-chip>
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="item-span"
                >
                  {{`
                    ${item.customerName}
                    (${item.customerPhone.slice(-4)})
                  `}}
                </span>
              </template>
              <span>{{ comm.makePhoneNumber(item.customerPhone) }}</span>
            </v-tooltip>
          </div>
          <div class="text-item mr-4">
            <input
              type="file"
              @change="fileSet($event, 'saleFile')"
              accept=".pdf, image/*"
              class="file-input"
              ref="saleFileInput"
            >
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >계약서</v-chip>
            <span
              class="item-span mr-1"
              v-if="item.file == null"
            >계약서 없음</span>
            <v-chip
              color="info mr-1"
              small
              close
              close-icon="mdi-download"
              @click="fileDownload(item.file)"
              @click-close="fileDownload(item.file)"
              link
              v-else
            >
              {{ item.file.name }}
            </v-chip>
            <v-tooltip
              top
              color="black"
              v-if="item.status !== 1 && item.disabled !== 1 && item.file !== null"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="deleteDocFunc(item.file.no)"
                >
                  <v-icon size="18px">mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>
                계약서 삭제
              </span>
            </v-tooltip>
            <v-tooltip
              top
              color="black"
              v-if="item.status !== 1 && item.disabled !== 1"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="$refs.saleFileInput.value=null, $refs.saleFileInput.click()"
                >
                  <v-icon size="18px">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>
                계약서 {{ item.file === null ? '첨부' : '수정' }}
              </span>
            </v-tooltip>
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="getHistories('sales.file', 'file', '계약서 수정이력')"
                >
                  <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                </v-btn>
              </template>
              <span>
                계약서 수정이력
              </span>
            </v-tooltip>
          </div>
          <div class="text-item mr-4">
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >영업담당자</v-chip>
            <span class="item-span mr-1">
              {{
                `${comm.getGroupFullPath(groups, item.managerGroupNo)} ${item.managerName}`
              }}
            </span>
            <div
              class="update-picker-wrap"
              v-if="item.status !== 1 && item.disabled !== 1 && checkFuncPermission('manager')"
            >
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    @click="getStaffsList(true, 'managerPicker')"
                  >
                    <v-icon size="18px">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>
                  영업담당자 변경
                </span>
              </v-tooltip>
              <v-card
                class="staffs-picker"
                v-click-outside="pickerInit"
                v-if="managerPicker === true"
              >
                <v-text-field
                  :autofocus="true"
                  dense
                  flat
                  hide-details
                  v-model="staffsText"
                  placeholder="영업담당자 검색"
                  @input="staffsFiltering"
                  color="black"
                  outlined
                  solo
                ></v-text-field>
                <v-list v-if="staffsFilter.length > 0">
                  <v-list-item
                    v-for="(row, i) in staffsFilter"
                    :key="i"
                    dense
                    nav
                    link
                    @click="updateManager(row)"
                  >
                    <v-list-item-title>
                      <v-chip
                        small
                        class="pr-1 pl-1 mr-1"
                        link
                        outlined
                        label
                      >
                        {{ row.group }}
                      </v-chip>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="staffsFilter.length === 0">
                  <v-list-item dense>
                    <v-list-item-title>{{
                      staffsText == null
                      || staffsText.trim().length === 0
                      ?'검색어를 입력해주세요.'
                      :'데이터가 없습니다.'
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>

            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="getHistories('sales.update', 'manager', '영업담당자 변경이력')"
                >
                  <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                </v-btn>
              </template>
              <span>
                영업담당자 변경이력
              </span>
            </v-tooltip>
          </div>
          <div class="text-item mr-4">
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >영업그룹</v-chip>
            <span class="item-span mr-1">
              {{
                `${comm.getGroupFullPath(groups, item.group)}`
              }}
            </span>
            <div
              class="update-picker-wrap"
              v-if="item.status !== 1 && item.disabled !== 1 && checkFuncPermission('manager')"
            >
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    @click="getGroupList('groupPicker')"
                  >
                    <v-icon size="18px">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>
                  영업그룹 변경
                </span>
              </v-tooltip>
              <v-card
                class="staffs-picker"
                v-click-outside="pickerInit"
                v-if="groupPicker === true"
              >
                <v-text-field
                  :autofocus="true"
                  dense
                  flat
                  hide-details
                  v-model="groupText"
                  placeholder="영업그룹 검색"
                  @input="groupFiltering"
                  color="black"
                  outlined
                  solo
                ></v-text-field>
                <v-list v-if="groupFilter.length > 0">
                  <v-list-item
                    v-for="(row, i) in groupFilter"
                    :key="i"
                    dense
                    nav
                    link
                    @click="updateGroup(row)"
                  >
                    <v-list-item-title>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="groupFilter.length === 0">
                  <v-list-item dense>
                    <v-list-item-title>{{
                      groupText == null
                      || groupText.trim().length === 0
                      ?'검색어를 입력해주세요.'
                      :'데이터가 없습니다.'
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>

            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="getHistories('sales.update', 'group', '영업그룹 변경이력')"
                >
                  <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                </v-btn>
              </template>
              <span>
                영업그룹 변경이력
              </span>
            </v-tooltip>
          </div>
        </div>
        <!-- 고객정보 끝 -->

        <!-- 상품정보 시작 -->
        <div class="content-row align-start">
          <p class="content-tit mr-4">
            상품정보
          </p>
          <div class="item-wrap">
            <div class="item-row">
              <div class="text-item mr-4">
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >상품명</v-chip>
                <span class="item-span">{{ item.productName }}</span>
                <v-tooltip
                  right
                  color="black"
                  v-if="item.productType === 0"
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      small
                      color="primary"
                      class="mb-1"
                      v-on="on"
                      @mouseenter="getProductRooms(item.productNo)"
                    >
                      <v-icon size="18px">mdi-chat-question</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="currentRooms === null"></span>
                  <span
                    class="pt-10"
                    v-else-if="currentRooms.length > 0"
                  >
                    <span
                      v-for="room in currentRooms"
                      :key="room.no"
                    >
                      {{ room.name }}
                      <br />
                    </span>
                  </span>
                  <span v-else>상품에 적용된 리딩방이 없습니다.</span>
                </v-tooltip>
              </div>
              <div
                class="text-item mr-4"
                v-if="item.productType === 0"
              >
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >이용기간</v-chip>
                <span class="item-span">
                  {{item.productTerm}}개월
                  <span
                    class="info--text"
                    v-if="item.productFreeTerm > 0"
                  >
                    + {{ item.productFreeTerm }}개월
                  </span>
                </span>
              </div>
              <div
                class="text-item mr-4"
                v-if="item.productType === 1"
              >
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >수량</v-chip>
                <span class="item-span">
                  {{ item.quantity }}
                </span>
                <div class="update-picker-wrap">
                  <v-tooltip
                    top
                    color="black"
                    v-if="item.status !== 1 && item.disabled !== 1"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="primary"
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          quantityUpdatePicker = true;
                          quantityUpdateValue = item.quantity;
                        "
                      >
                        <v-icon size="18px">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      수량 변경
                    </span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="primary"
                        v-on="on"
                        v-bind="attrs"
                        @click="getHistories('sales.update', 'quantity', '수량변경 이력')"
                      >
                        <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      수량변경 이력
                    </span>
                  </v-tooltip>
                  <v-card
                    class="discount-picker"
                    v-click-outside="pickerInit"
                    v-if="quantityUpdatePicker === true"
                  >
                    <div class="d-flex align-center">
                      <v-text-field
                        v-model="quantityUpdateValue"
                        outlined
                        dense
                        hide-details
                        label="수량"
                        :rules="[quantityRule]"
                      />
                      <v-btn
                        class="ml-2"
                        elevation="0"
                        color="primary"
                        @click="updateQuantity"
                      >
                        적용
                      </v-btn>
                    </div>
                  </v-card>
                </div>
              </div>
              <div class="text-item mr-4">
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >상품가격</v-chip>
                <span class="item-span mr-1">
                  {{ comm.priceToString(item.productPrice) }}
                </span>
              </div>
              <div class="text-item mr-4">
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >판매가조정</v-chip>
                <span class="item-span">
                  {{`
                    ${item.discount > 0 ? '+' : ''}${comm.priceToString(item.discount)}
                  `}}
                </span>
                <div class="update-picker-wrap">
                  <v-tooltip
                    top
                    color="black"
                    v-if="item.status !== 1 && item.disabled !== 1"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="primary"
                        v-on="on"
                        v-bind="attrs"
                        @click="discountUpdatePicker = true"
                      >
                        <v-icon size="18px">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      판매가조정 수정
                    </span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="primary"
                        v-on="on"
                        v-bind="attrs"
                        @click="getHistories('sales.update', 'discount', '판매가조정 이력')"
                      >
                        <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      판매가조정 이력
                    </span>
                  </v-tooltip>
                  <v-card
                    class="discount-picker"
                    v-click-outside="pickerInit"
                    v-if="discountUpdatePicker === true"
                  >
                    <v-radio-group
                      class="mt-0"
                      @change="discountUpdateRadio = $event"
                      dense
                      row
                      :value="discountUpdateRadio"
                    >
                      <v-radio
                        label="할인"
                        :value="-1"
                        class="mr-4"
                      ></v-radio>
                      <v-radio
                        label="추가"
                        :value="1"
                        class="mr-4"
                      ></v-radio>
                    </v-radio-group>
                    <div class="d-flex align-center">
                      <price-text
                        v-model="discountUpdateValue"
                        outlined
                        dense
                        label="금액(원)"
                        :rules="[priceRule]"
                        hide-details
                      />
                      <v-btn
                        class="ml-2"
                        elevation="0"
                        color="primary"
                        @click="updateDiscount"
                      >
                        적용
                      </v-btn>
                    </div>
                  </v-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 상품정보 끝 -->

        <!-- 결제정보 시작 -->
        <div class="content-row align-start">
          <p class="content-tit mr-4">
            결제정보
          </p>
          <div class="item-wrap fix-width">
            <!-- 결제건 -->
            <div
              class="pay-row"
              v-for="(row, i) in item.payments"
              :key="i"
            >
              <div class="pay-info">
                <v-tooltip
                  top
                  color="black"
                  v-if="
                    item.disabled !== 1 && item.status !== 1
                    && checkFuncPermission('paymentStatus')
                  "
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      :color="row.status === 1 ? 'success' : 'error'"
                      small
                      class="mr-2 confirm-chip"
                      link
                      v-on="on"
                      v-bind="attrs"
                      @click="updatePaymentsStatus(row)"
                    >
                      {{ row.status === 1 ? '승인' : '미승인' }}
                    </v-chip>
                  </template>
                  <span>
                    {{ row.status === 0 ? '결제 승인' : '미승인으로 변경' }}
                  </span>
                </v-tooltip>
                <v-chip
                  v-else
                  :color="row.status === 1 ? 'success' : 'error'"
                  small
                  class="mr-2 confirm-chip"
                >
                  {{ row.status === 1 ? '승인' : '미승인' }}
                </v-chip>
                <v-chip
                  small
                  label
                >
                  {{ row.pgName }}
                </v-chip>
                <v-tooltip
                  top
                  color="black"
                  v-if="row.pgType === 1"
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      v-if="JSON.parse(row.paymentInfo).cardType === 1"
                      class="ml-1"
                      label
                      small
                      color="success"
                      v-bind="attrs"
                      v-on="on"
                      link
                    >
                      수기결제
                    </v-chip>
                    <v-chip
                      v-else-if="JSON.parse(row.paymentInfo).cardType === 0"
                      class="ml-1"
                      label
                      small
                      color="info"
                      v-bind="attrs"
                      v-on="on"
                      link
                    >
                      SMS인증
                    </v-chip>
                  </template>
                  <span v-if="JSON.parse(row.paymentInfo).cardType === 0">
                    {{ `카드사: ${JSON.parse(row.paymentInfo).company}` }}<br />
                    {{ `할부: ${JSON.parse(row.paymentInfo).installment}개월` }}<br />
                    {{ `전화번호: ${comm.makePhoneNumber(JSON.parse(row.paymentInfo).phone)}` }}
                  </span>
                  <span v-if="JSON.parse(row.paymentInfo).cardType === 1">
                    {{ `카드사: ${JSON.parse(row.paymentInfo).company}` }}<br />
                    {{ `할부: ${JSON.parse(row.paymentInfo).installment}개월` }}<br />
                    {{ `전화번호: ${comm.makePhoneNumber(JSON.parse(row.paymentInfo).phone)}` }}<br />
                    {{ `카드번호: ${JSON.parse(row.paymentInfo).cardNumber}` }}<br />
                    {{ `비밀번호: ${JSON.parse(row.paymentInfo).password}` }}<br />
                    {{ `생년월일: ${JSON.parse(row.paymentInfo).birthday}` }}<br />
                  </span>
                </v-tooltip>
                <span class="ml-3 pay-text">
                  {{ row.payer }}
                </span>
                <span class="ml-3 pay-text">
                  {{ comm.priceToString(row.amount) }}
                </span>
                <span class="pay-text ml-3">
                  {{ time.makeLocalTime(row.date, 'date') }}
                </span>
                <v-chip
                  v-if="row.files != undefined && row.files.length > 0"
                  color="info"
                  class="ml-3"
                  outlined
                  small
                  close
                  label
                  close-icon="mdi-download"
                  link
                  @click="fileDownload(row.files[0])"
                  @click:close="fileDownload(row.files[0])"
                >
                  전표: {{ row.files[0].name }}
                </v-chip>
                <v-chip
                  v-else
                  class="ml-3"
                  label
                  small
                >
                  전표없음
                </v-chip>
                <v-tooltip
                  top
                  color="black"
                  v-if="item.status !== 1
                    && item.disabled !== 1 && row.status === 0 && row.files.length > 0"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      @click="deletePaymentFileFunc(row.no, row.files[0].no)"
                    >
                      <v-icon size="18px">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    전표 삭제
                  </span>
                </v-tooltip>
                <v-tooltip
                  top
                  color="black"
                  v-if="item.status !== 1 && item.disabled !== 1 && row.status === 0"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      @click="
                        payUpdateOriginFileNo=row.files.length > 0 ? row.files[0].no : null,
                        payUpdateNo=row.no,
                        $refs.payUpdateFileInput.value=null,
                        $refs.payUpdateFileInput.click()
                      "
                    >
                      <v-icon size="18px">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    전표 {{ row.files.length === 0 ? '첨부' : '수정' }}
                  </span>
                </v-tooltip>
              </div>
              <v-tooltip
                top
                color="black"
                v-if="item.status !== 1 && item.disabled !== 1"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    color="error"
                    class="ml-6"
                    elevation
                    v-bind="attrs"
                    v-on="on"
                    @click="deletePayment(row)"
                    v-show="row.status === 0"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>결제건 삭제</span>
              </v-tooltip>
              <v-tooltip
                top
                color="black"
                v-if="item.status !== 1 && item.disabled !== 1"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question ml-1 mr-2"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                    v-show="row.status === 1"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  승인된 결제건은 삭제할 수 없습니다.<br />
                  수정이 필요한 경우 미승인으로 변경 후 삭제해주세요.
                </span>
              </v-tooltip>
            </div>
            <input
              type="file"
              @change="fileSet($event, 'payUpdateFile')"
              accept=".pdf, image/*"
              class="file-input"
              ref="payUpdateFileInput"
            >

            <div class="d-flex justify-end pay-form-wrap">
              <v-btn
                v-if="item.status !== 1 && item.disabled !== 1"
                elevation
                small
                @click="getPgList(item.method), paymentAppender = true"
                outlined
              >
                결제건 추가
              </v-btn>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    @click="getHistories('sales.payment', 'paymentInfo', '결제건이력')"
                  >
                    <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                  </v-btn>
                </template>
                <span>
                  결제건 이력
                </span>
              </v-tooltip>
              <v-card
                class="pay-form"
                v-if="paymentAppender === true"
              >
                <div class="input-wrap">
                  <div class="text-input-wrap">
                    <v-text-field
                      v-model="payerName"
                      outlined
                      dense
                      label="결제자명"
                      hide-details
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <price-text
                      v-model="payPrice"
                      outlined
                      dense
                      hide-details
                      label="금액(원)"
                      :rules="[priceRule]"
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <v-menu
                      v-model="payDatePicker"
                      :close-on-content-click="false"
                      :nudge-left="90"
                      origin="right top"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="payDate"
                          label="결제일"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          hide-details
                          color="black"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="payDate"
                        @input="payDatePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <div
                  class="d-flex mt-3 align-center"
                  v-if="item.productType === 0"
                >
                  <v-select
                    outlined
                    dense
                    label="결제방법1"
                    v-model="pg.value"
                    :items="pg.data"
                    item-text="name"
                    return-object
                    hide-details
                  ></v-select>
                  <v-select
                    v-if="pg.value != undefined
                    && pg.value.type === 1"
                    class="ml-2"
                    outlined
                    dense
                    label="카드사"
                    :value="company.value"
                    :items="company.data"
                    @change="company.value = $event"
                    hide-details
                    item-text="name"
                  ></v-select>
                  <v-select
                    v-if="pg.value != undefined
                    && pg.value.type === 1"
                    class="ml-2"
                    outlined
                    dense
                    label="결제방식"
                    v-model="cardType.value"
                    :items="cardType.data"
                    hide-details
                    item-text="name"
                  ></v-select>
                </div>
                <div
                  class="d-flex mb-2 mt-3 align-center pay-input-wrap"
                  v-if="item.productType === 0
                    && pg.value != undefined
                    && pg.value.type === 1
                    && cardType.value === 1
                  "
                >

                  <div class="text-input-wrap">
                    <v-text-field
                      v-model="cardInfo.cardNumber"
                      outlined
                      dense
                      label="카드번호"
                      :rules="[numberLengthRule(cardInfo.cardNumber, 16)]"
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <v-text-field
                      v-model="cardInfo.validThru"
                      outlined
                      dense
                      label="유효기간(MMYY)"
                      :rules="[numberLengthRule(cardInfo.validThru, 4)]"
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <v-text-field
                      v-model="cardInfo.password"
                      outlined
                      dense
                      label="비밀번호(앞두자리)"
                      :rules="[numberLengthRule(cardInfo.password, 2)]"
                    />
                  </div>
                </div>
                <div
                  class="d-flex mb-2 mt-3 align-center pay-input-wrap"
                  v-if="pg.value != undefined
                    && pg.value.type === 1"
                >
                  <div class="text-input-wrap">
                    <v-text-field
                      v-model="cardInfo.installment"
                      outlined
                      dense
                      label="할부(개월)"
                      :rules="[installmentRule]"
                    />
                  </div>
                  <div
                    class="text-input-wrap ml-2"
                    v-if="pg.value != undefined
                      && pg.value.type === 1
                      && cardType.value === 1"
                  >
                    <v-text-field
                      v-model="cardInfo.birthday"
                      outlined
                      dense
                      label="생년월일(YYMMDD)"
                      :rules="[numberLengthRule(cardInfo.birthday, 6)]"
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <v-text-field
                      v-model="cardInfo.phone"
                      outlined
                      dense
                      label="전화번호"
                      :rules="[phoneNumberRule]"
                    />
                  </div>
                </div>
                <input
                  type="file"
                  @change="fileSet($event, 'payFile')"
                  accept=".pdf, image/*"
                  class="file-input"
                  ref="payFileInput"
                >
                <v-btn
                  v-if="item.status !== 1 && payFile === null"
                  elevation
                  color="info"
                  class="pl-2 mt-2"
                  @click="$refs.payFileInput.value=null, $refs.payFileInput.click()"
                >
                  <v-icon>mdi-paperclip</v-icon>전표첨부
                </v-btn>
                <v-chip
                  v-else
                  class="mt-2 flex-shrink-0"
                  label
                  @click="payFile = null"
                  @click:close="payFile = null"
                  link
                  close
                >
                  {{ payFile.name }}
                </v-chip>
                <div class="d-flex justify-end mt-3">
                  <v-btn
                    elevation
                    color="primary"
                    :disabled="paymentVaildChecker()"
                    @click="paymentAppend()"
                  >추가</v-btn>
                  <v-btn
                    elevation
                    @click="paymentAppenderInit"
                    class="ml-2"
                  >취소</v-btn>
                </div>
              </v-card>
            </div>
          </div>
        </div>
        <!-- 결제정보 끝 -->

        <!-- 매출합계 시작 -->
        <div class="content-row align-start">
          <p class="content-tit mr-4">
            매출합계
          </p>
          <div class="item-wrap fix-width">
            <!-- 서비스이용기간 -->
            <div
              class="item-row total"
              v-if="
                item.status === 1
                && item.productType === 0
                && item.serviceStart !== null
              "
            >
              <div>
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1"
                  small
                >서비스이용기간</v-chip>
              </div>
              <span class="total-data">
                {{
                  `${time.makeLocalTime(item.serviceStart, 'date')}
                  ~ ${time.makeLocalTime(item.serviceEnd, 'date')}`
                }}
              </span>
            </div>
            <div
              class="item-row total"
              v-if="
                item.status === 1
                && item.productType === 0
                && item.serviceStart !== null
              "
            >
              <div>
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1"
                  small
                >일할계산종료일</v-chip>
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="question ml-1"
                      small
                      color="warning"
                      v-on="on"
                      v-bind="attrs"
                    >mdi-help-circle-outline</v-icon>
                  </template>
                  <span>
                    일할계산종료일: 서비스시작일 + 상품이용기간<br />
                    ※ 상품무료이용기간은 환불시 일할계산에 포함되지 않습니다.
                  </span>
                </v-tooltip>
              </div>
              <span class="total-data">
                {{
                  time.moment(time.makeLocalDate(item.serviceStart, 'date'))
                  .add(item.productTerm, 'month')
                  .format('YYYY-MM-DD')
                }}
              </span>
            </div>
            <div class="item-row total">
              <v-chip
                outlined
                label
                class="pl-1 pr-1"
                small
              >판매가</v-chip>
              <span class="total-data">
                {{
                  comm.priceToString(
                    (item.productPrice * item.quantity) + item.discount
                  )
                }}
              </span>
            </div>
            <div class="item-row total">
              <v-chip
                outlined
                label
                class="pl-1 pr-1"
                small
              >결제총액</v-chip>
              <span
                class="total-data"
                v-if="item.payments != undefined && item.payments.length > 0"
              >
                {{ comm.priceToString(item.amount) }}
              </span>
              <span
                class="total-data"
                v-else
              >0 원</span>
            </div>
            <div class="item-row total">
              <v-chip
                outlined
                label
                class="pl-1 pr-1"
                small
              >결제차액</v-chip>
              <span
                v-if="item.payments != undefined && item.payments.length > 0"
                class="total-data"
                :class="
                  item.amount
                  - (
                    (item.productPrice * item.quantity) + item.discount
                  ) < 0 ? 'error--text' : ''
                "
              >
                {{
                  comm.priceToString(
                    item.amount - ((item.productPrice * item.quantity) + item.discount)
                  )
                }}
              </span>
              <span
                class="total-data"
                v-else
              >
                {{
                  comm.priceToString(((item.productPrice * item.quantity) + item.discount) * -1)
                }}
              </span>
            </div>
            <div class="item-row total border">
              <v-chip
                outlined
                label
                class="pl-1 pr-1"
                small
              >
                {{ item.type === 0 ? '매출' : '환불' }}합계
              </v-chip>
              <span class="total-data bold">
                {{
                  comm.priceToString(
                    item.amount
                  )
                }}
              </span>
              <!-- <span class="total-data bold" v-else>0 원</span> -->
            </div>
          </div>
        </div>
        <!-- 매출합계 끝 -->
      </div>
      <!-- 매출인 경우 끝 -->

      <!-- 환불인 경우 시작 -->
      <div
        class="content-wrap"
        v-else-if="item.type === 1"
      >
        <div
          class="content-row"
          v-if="item.originSale !== null"
        >
          <p class="content-tit mr-4">
            매출정보
          </p>
          <div>
            <div class="d-flex">
              <div class="text-item mr-4">
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >매출번호</v-chip>
                <span class="item-span">
                  {{ item.originSale.no }}
                  <span
                    v-if="item.originSale.status === 0"
                    class="error--text"
                  >
                    (미승인)
                  </span>
                  <span
                    v-if="item.originSale.status === 2"
                    class="error--text"
                  >
                    (가결제)
                  </span>
                </span>
              </div>
              <div class="text-item mr-4">
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >고객</v-chip>
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="item-span"
                    >
                      {{`
                        ${item.originSale.customerName}
                        (${item.originSale.customerPhone.slice(-4)})
                      `}}
                    </span>
                  </template>
                  <span>{{ comm.makePhoneNumber(item.originSale.customerPhone) }}</span>
                </v-tooltip>
              </div>
              <div class="text-item mr-4">
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >계약서</v-chip>
                <span
                  class="item-span mr-1"
                  v-if="
                    item.originSale.file === null
                    || Object.keys(item.originSale.file).length <= 0
                  "
                >
                  계약서 없음
                </span>
                <v-chip
                  color="info mr-1"
                  small
                  close
                  close-icon="mdi-download"
                  @click="fileDownload(item.originSale.file)"
                  @click-close="fileDownload(item.originSale.file)"
                  link
                  v-else
                >
                  {{ item.originSale.file.name }}
                </v-chip>
              </div>
              <div class="text-item mr-4">
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >영업담당자(매출)</v-chip>
                <span class="item-span mr-1">
                  {{
                    `${comm.getGroupFullPath(
                      groups, item.originSale.managerGroupNo
                    )} ${item.originSale.managerName}`
                  }}
                </span>
              </div>
              <div class="text-item mr-4">
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >상품</v-chip>
                <span class="item-span mr-1">
                  {{ item.originSale.productName }}
                  <span class="item-span"> /
                    {{item.productTerm}}개월
                    <span
                      class="info--text"
                      v-if="item.productFreeTerm > 0"
                    >
                      + {{ item.productFreeTerm }}개월
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div
              class="d-flex mt-2"
              v-if="item.status === 0 && item.originSale.status === 1"
            >
              <div class="text-item mr-4">
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >매출일</v-chip>
                <span class="item-span mr-1">
                  {{ time.makeLocalTime(item.originSale.date, 'date') }}
                </span>
              </div>
              <div class="text-item mr-4">
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >일할계산종료일</v-chip>
                <span class="item-span mr-1">
                  {{
                    time.makeLocalTime(
                      time.moment(time.makeLocalTime(item.originSale.date))
                      .add(item.originSale.productTerm, 'M'), 'date'
                    )
                  }}
                </span>
              </div>
              <div class="text-item mr-4">
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1 mr-1"
                  small
                >매출액</v-chip>
                <span class="item-span mr-1">
                  {{ comm.priceToString(item.originSale.amount) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="content-row"
          v-if="item.originSale !== null"
        >
          <p class="content-tit mr-4">
            결제정보
          </p>
          <div class="item-wrap fix-width">
            <div
              class="pay-row"
              v-for="(row, i) in item.originSale.payments.filter((r) => r.status === 1)"
              :key="i"
            >
              <div class="pay-info">
                <v-chip
                  small
                  label
                >
                  {{ row.pgName }}
                </v-chip>
                <v-tooltip
                  top
                  color="black"
                  v-if="row.pgType === 1"
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      v-if="JSON.parse(row.paymentInfo).cardType === 1"
                      class="ml-1"
                      label
                      small
                      color="success"
                      v-bind="attrs"
                      v-on="on"
                      link
                    >
                      수기결제
                    </v-chip>
                    <v-chip
                      v-else-if="JSON.parse(row.paymentInfo).cardType === 0"
                      class="ml-1"
                      label
                      small
                      color="info"
                      v-bind="attrs"
                      v-on="on"
                      link
                    >
                      SMS인증
                    </v-chip>
                  </template>
                  <span v-if="JSON.parse(row.paymentInfo).cardType === 0">
                    {{ `카드사: ${JSON.parse(row.paymentInfo).company}` }}<br />
                    {{ `할부: ${JSON.parse(row.paymentInfo).installment}개월` }}<br />
                    {{ `전화번호: ${comm.makePhoneNumber(JSON.parse(row.paymentInfo).phone)}` }}
                  </span>
                  <span v-if="JSON.parse(row.paymentInfo).cardType === 1">
                    {{ `카드사: ${JSON.parse(row.paymentInfo).company}` }}<br />
                    {{ `할부: ${JSON.parse(row.paymentInfo).installment}개월` }}<br />
                    {{ `전화번호: ${comm.makePhoneNumber(JSON.parse(row.paymentInfo).phone)}` }}<br />
                    {{ `카드번호: ${JSON.parse(row.paymentInfo).cardNumber}` }}<br />
                    {{ `비밀번호: ${JSON.parse(row.paymentInfo).password}` }}<br />
                    {{ `생년월일: ${JSON.parse(row.paymentInfo).birthday}` }}<br />
                  </span>
                </v-tooltip>
                <span class="ml-3 pay-text">
                  {{ row.payer }}
                </span>
                <span class="ml-3 pay-text">
                  {{ comm.priceToString(row.amount) }}
                </span>
                <span class="pay-text ml-3">
                  {{ time.makeLocalTime(row.date, 'date') }}
                </span>
                <v-chip
                  v-if="row.files != undefined && row.files.length > 0"
                  color="info"
                  class="ml-3"
                  outlined
                  small
                  close
                  label
                  close-icon="mdi-download"
                  link
                  @click="fileDownload(row.files[0])"
                  @click:close="fileDownload(row.files[0])"
                >
                  전표: {{ row.files[0].name }}
                </v-chip>
                <v-chip
                  v-else
                  class="ml-3"
                  label
                  small
                >
                  전표없음
                </v-chip>
              </div>
            </div>
            <span
              v-if="item.originSale.payments.length <= 0"
              class="gray--text item-span"
            >
              기존 매출건에 결제정보가 없습니다.
            </span>
          </div>
        </div>
        <div class="content-row">
          <p class="content-tit mr-4">
            환불정보
          </p>
          <div class="text-item mr-4">
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >영업담당자(환불)</v-chip>
            <span class="item-span mr-1">
              {{
                `${comm.getGroupFullPath(
                  groups, item.managerGroupNo
                )} ${item.managerName}`
              }}
            </span>
            <div class="update-picker-wrap">
              <v-tooltip
                top
                color="black"
                v-if="item.status !== 1 && item.disabled !== 1"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    @click="getStaffsList(true, 'managerPicker')"
                  >
                    <v-icon size="18px">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>
                  영업담당자 변경
                </span>
              </v-tooltip>
              <v-card
                class="staffs-picker"
                v-click-outside="pickerInit"
                v-if="managerPicker === true"
              >
                <v-text-field
                  :autofocus="true"
                  dense
                  flat
                  hide-details
                  v-model="staffsText"
                  placeholder="영업담당자 검색"
                  @input="staffsFiltering"
                  color="black"
                  outlined
                  solo
                ></v-text-field>
                <v-list v-if="staffsFilter.length > 0">
                  <v-list-item
                    v-for="(row, i) in staffsFilter"
                    :key="i"
                    dense
                    nav
                    link
                    @click="updateManager(row)"
                  >
                    <v-list-item-title>
                      <v-chip
                        small
                        class="pr-1 pl-1 mr-1"
                        link
                        outlined
                        label
                      >
                        {{ row.group }}
                      </v-chip>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="staffsFilter.length === 0">
                  <v-list-item dense>
                    <v-list-item-title>{{
                      staffsText == null
                      || staffsText.trim().length === 0
                      ?'검색어를 입력해주세요.'
                      :'데이터가 없습니다.'
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="getHistories('sales.update', 'manager', '영업담당자 변경이력')"
                >
                  <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                </v-btn>
              </template>
              <span>
                영업담당자 변경이력
              </span>
            </v-tooltip>
          </div>
          <div class="text-item mr-4">
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >영업그룹(환불)</v-chip>
            <span class="item-span mr-1">
              {{
                `${comm.getGroupFullPath(groups, item.group)}`
              }}
            </span>
            <div
              class="update-picker-wrap"
              v-if="item.status !== 1 && item.disabled !== 1 && checkFuncPermission('manager')"
            >
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    @click="getGroupList('groupPicker')"
                  >
                    <v-icon size="18px">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>
                  영업그룹 변경
                </span>
              </v-tooltip>
              <v-card
                class="staffs-picker"
                v-click-outside="pickerInit"
                v-if="groupPicker === true"
              >
                <v-text-field
                  :autofocus="true"
                  dense
                  flat
                  hide-details
                  v-model="groupText"
                  placeholder="영업그룹 검색"
                  @input="groupFiltering"
                  color="black"
                  outlined
                  solo
                ></v-text-field>
                <v-list v-if="groupFilter.length > 0">
                  <v-list-item
                    v-for="(row, i) in groupFilter"
                    :key="i"
                    dense
                    nav
                    link
                    @click="updateGroup(row)"
                  >
                    <v-list-item-title>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="groupFilter.length === 0">
                  <v-list-item dense>
                    <v-list-item-title>{{
                      groupText == null
                      || groupText.trim().length === 0
                      ?'검색어를 입력해주세요.'
                      :'데이터가 없습니다.'
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>

            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="getHistories('sales.update', 'group', '영업그룹 변경이력')"
                >
                  <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                </v-btn>
              </template>
              <span>
                영업그룹 변경이력
              </span>
            </v-tooltip>
          </div>
        </div>
        <!-- 계좌환불, 카드취소 겸용 input -->
        <input
          type="file"
          @change="fileSet($event, 'payFile')"
          accept=".pdf, image/*"
          class="file-input"
          ref="payFileInput"
        >
        <!-- 계좌환불 -->
        <div
          class="content-row align-start"
          v-if="item.originSale.method === 0"
        >
          <p class="content-tit mr-4">
            계좌환불
          </p>
          <div class="item-wrap fix-width">
            <div
              class="pay-row"
              v-for="
                (row, i)
                  in item.payments.filter((r) => JSON.parse(r.paymentInfo).type === 'bank')
              "
              :key="i"
            >
              <div class="pay-info">
                <v-tooltip
                  top
                  color="black"
                  v-if="item.status !== 1 && item.disabled !== 1"
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      :color="row.status === 1 ? 'success' : 'error'"
                      small
                      class="mr-2 confirm-chip"
                      link
                      v-on="on"
                      v-bind="attrs"
                      @click="updatePaymentsStatus(row, 'bank')"
                    >
                      {{ row.status === 1 ? '입금완료' : '환불요청' }}
                    </v-chip>
                  </template>
                  <span>
                    {{ row.status === 0 ? '입금완료로 변경' : '환불요청으로 변경' }}
                  </span>
                </v-tooltip>
                <v-chip
                  v-else
                  :color="row.status === 1 ? 'success' : 'error'"
                  small
                  class="mr-2 confirm-chip"
                >
                  {{ row.status === 1 ? '입금완료' : '환불요청' }}
                </v-chip>
                <div class="pay-info">
                  <v-chip
                    small
                    label
                  >
                    {{ JSON.parse(row.paymentInfo).bank }}
                  </v-chip>
                  <span class="ml-2 pay-text">
                    {{ JSON.parse(row.paymentInfo).account }}
                  </span>
                  <span class="ml-2 pay-text">
                    {{ row.payer }}
                  </span>
                  <span class="pay-text ml-2">
                    {{ comm.priceToString(row.amount * -1) }}
                  </span>
                </div>
                <v-chip
                  v-if="row.files != undefined && row.files.length > 0"
                  color="info"
                  class="ml-3"
                  outlined
                  small
                  close
                  label
                  close-icon="mdi-download"
                  link
                  @click="fileDownload(row.files[0])"
                  @click:close="fileDownload(row.files[0])"
                >
                  전표: {{ row.files[0].name }}
                </v-chip>
                <v-chip
                  v-else
                  class="ml-3"
                  label
                  small
                >
                  전표없음
                </v-chip>
                <v-tooltip
                  top
                  color="black"
                  v-if="item.status !== 1
                    && item.disabled !== 1 && row.status === 0 && row.files.length > 0"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      @click="deletePaymentFileFunc(row.no, row.files[0].no)"
                    >
                      <v-icon size="18px">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    전표 삭제
                  </span>
                </v-tooltip>
                <v-tooltip
                  top
                  color="black"
                  v-if="item.status !== 1 && item.disabled !== 1 && row.status === 0"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      @click="
                        payUpdateOriginFileNo=row.files.length > 0 ? row.files[0].no : null,
                        payUpdateNo=row.no,
                        $refs.payUpdateFileInput.value=null,
                        $refs.payUpdateFileInput.click()
                      "
                    >
                      <v-icon size="18px">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    전표 {{ row.files.length === 0 ? '첨부' : '수정' }}
                  </span>
                </v-tooltip>
              </div>
              <v-tooltip
                top
                color="black"
                v-if="item.status !== 1 && item.disabled !== 1"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    color="error"
                    class="ml-6"
                    elevation
                    v-bind="attrs"
                    v-on="on"
                    @click="deletePayment(row)"
                    v-show="row.status === 0"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>계좌환불 삭제</span>
              </v-tooltip>
              <v-tooltip
                top
                color="black"
                v-if="item.status !== 1 && item.disabled !== 1"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question ml-1 mr-2"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                    v-show="row.status === 1"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  입금완료된 계좌환불은 삭제할 수 없습니다.<br />
                  수정이 필요한 경우 환불요청으로 변경 후 삭제해주세요.
                </span>
              </v-tooltip>
            </div>
            <input
              type="file"
              @change="fileSet($event, 'payUpdateFile')"
              accept=".pdf, image/*"
              class="file-input"
              ref="payUpdateFileInput"
            >
            <div class="d-flex justify-end pay-form-wrap">
              <v-btn
                v-if="
                  item.status !== 1 && item.disabled !== 1
                  && checkFuncPermission('paymentStatus')
                "
                elevation
                small
                @click="paymentBankAppender = true"
                outlined
              >
                계좌환불 추가
              </v-btn>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    @click="getHistories('sales.payment', 'paymentRefundBank', '계좌환불이력')"
                  >
                    <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                  </v-btn>
                </template>
                <span>
                  계좌환불 이력
                </span>
              </v-tooltip>
              <v-card
                class="pay-form"
                v-if="paymentBankAppender === true"
              >
                <div class="input-wrap">
                  <div class="text-input-wrap">
                    <v-text-field
                      v-model="payerName"
                      outlined
                      dense
                      label="예금주"
                      hide-details
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <price-text
                      v-model="payPrice"
                      outlined
                      dense
                      hide-details
                      label="금액(원)"
                      :rules="[priceRule]"
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <v-select
                      outlined
                      dense
                      label="은행"
                      v-model="bank.value"
                      :items="bank.data"
                      item-text="name"
                      hide-details
                    ></v-select>
                  </div>
                </div>
                <div class="d-flex mt-3 align-center">
                  <v-text-field
                    v-model="bankAccount"
                    outlined
                    dense
                    label="계좌번호"
                    :rules="[bankAccountRule(bankAccount)]"
                  />
                </div>
                <v-btn
                  v-if="payFile === null"
                  elevation
                  color="info"
                  class="pl-2 mt-2"
                  @click="$refs.payFileInput.value=null, $refs.payFileInput.click()"
                >
                  <v-icon>mdi-paperclip</v-icon>전표첨부
                </v-btn>
                <v-chip
                  v-else
                  class="mt-2 flex-shrink-0"
                  label
                  @click="payFile = null"
                  @click:close="payFile = null"
                  link
                  close
                >
                  {{ payFile.name }}
                </v-chip>
                <div class="d-flex justify-end mt-3">
                  <v-btn
                    elevation
                    color="primary"
                    :disabled="paymentVaildChecker('refund')"
                    @click="paymentAppend('bank')"
                  >추가</v-btn>
                  <v-btn
                    elevation
                    @click="paymentAppenderInit"
                    class="ml-2"
                  >취소</v-btn>
                </div>
              </v-card>
            </div>
          </div>
        </div>
        <!-- 카드취소 -->
        <div
          class="content-row align-start"
          v-else-if="item.originSale.method === 1"
        >
          <p class="content-tit mr-4">
            카드취소
          </p>
          <div class="item-wrap fix-width">
            <div
              class="pay-row"
              v-for="
                (row, i)
                  in item.payments.filter((r) => JSON.parse(r.paymentInfo).type === 'card')
              "
              :key="i"
            >
              <div class="pay-info">
                <v-tooltip
                  top
                  color="black"
                  v-if="
                    item.status !== 1 && item.disabled !== 1
                    && checkFuncPermission('paymentStatus')
                  "
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      :color="row.status === 1 ? 'success' : 'error'"
                      small
                      class="mr-2 confirm-chip"
                      link
                      v-on="on"
                      v-bind="attrs"
                      @click="updatePaymentsStatus(row, 'card')"
                    >
                      {{ row.status === 1 ? '취소완료' : '취소요청' }}
                    </v-chip>
                  </template>
                  <span>
                    {{ row.status === 0 ? '취소완료로 변경' : '취소요청으로 변경' }}
                  </span>
                </v-tooltip>
                <v-chip
                  v-else
                  :color="row.status === 1 ? 'success' : 'error'"
                  small
                  class="mr-2 confirm-chip"
                >
                  {{ row.status === 1 ? '취소완료' : '취소요청' }}
                </v-chip>
                <div class="pay-info">
                  <v-chip
                    small
                    label
                  >
                    {{ JSON.parse(row.paymentInfo).company }}
                  </v-chip>
                  <span class="ml-2 pay-text">
                    {{ row.payer }}
                  </span>
                  <span class="pay-text ml-2">
                    {{ comm.priceToString(row.amount * -1) }}
                  </span>
                </div>
                <v-chip
                  v-if="row.files != undefined && row.files.length > 0"
                  color="info"
                  class="ml-3"
                  outlined
                  small
                  close
                  label
                  close-icon="mdi-download"
                  link
                  @click="fileDownload(row.files[0])"
                  @click:close="fileDownload(row.files[0])"
                >
                  전표: {{ row.files[0].name }}
                </v-chip>
                <v-chip
                  v-else
                  class="ml-3"
                  label
                  small
                >
                  전표없음
                </v-chip>
                <v-tooltip
                  top
                  color="black"
                  v-if="item.status !== 1 && item.disabled !== 1
                    && row.status === 0 && row.files.length > 0"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      @click="deletePaymentFileFunc(row.no, row.files[0].no)"
                    >
                      <v-icon size="18px">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    전표 삭제
                  </span>
                </v-tooltip>
                <v-tooltip
                  top
                  color="black"
                  v-if="item.status !== 1 && item.disabled !== 1 && row.status === 0"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      @click="
                        payUpdateOriginFileNo=row.files.length > 0 ? row.files[0].no : null,
                        payUpdateNo=row.no,
                        $refs.payUpdateFileInput.value=null,
                        $refs.payUpdateFileInput.click()
                      "
                    >
                      <v-icon size="18px">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    전표 {{ row.files.length === 0 ? '첨부' : '수정' }}
                  </span>
                </v-tooltip>
              </div>
              <v-tooltip
                top
                color="black"
                v-if="item.status !== 1 && item.disabled !== 1"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    color="error"
                    class="ml-6"
                    elevation
                    v-bind="attrs"
                    v-on="on"
                    @click="deletePayment(row)"
                    v-show="row.status === 0"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>계좌환불 삭제</span>
              </v-tooltip>
              <v-tooltip
                top
                color="black"
                v-if="item.status !== 1 && item.disabled !== 1"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question ml-1 mr-2"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                    v-show="row.status === 1"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  입금완료된 계좌환불은 삭제할 수 없습니다.<br />
                  수정이 필요한 경우 환불요청으로 변경 후 삭제해주세요.
                </span>
              </v-tooltip>
            </div>
            <input
              type="file"
              @change="fileSet($event, 'payUpdateFile')"
              accept=".pdf, image/*"
              class="file-input"
              ref="payUpdateFileInput"
            >
            <div class="d-flex justify-end pay-form-wrap">
              <v-btn
                v-if="item.status !== 1 && item.disabled !== 1"
                elevation
                small
                @click="paymentAppender = true"
                outlined
              >
                카드취소 추가
              </v-btn>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    @click="getHistories('sales.payment', 'paymentRefundCard', '카드취소이력')"
                  >
                    <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                  </v-btn>
                </template>
                <span>
                  카드취소 이력
                </span>
              </v-tooltip>
              <v-card
                class="pay-form"
                v-if="paymentAppender === true"
              >
                <div class="input-wrap">
                  <div class="text-input-wrap">
                    <v-text-field
                      v-model="payerName"
                      outlined
                      dense
                      label="명의자명"
                      hide-details
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <price-text
                      v-model="payPrice"
                      outlined
                      dense
                      hide-details
                      label="금액(원)"
                      :rules="[priceRule]"
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <v-select
                      outlined
                      dense
                      label="카드사"
                      v-model="company.value"
                      :items="company.data"
                      item-text="name"
                      hide-details
                    ></v-select>
                  </div>
                </div>
                <v-btn
                  v-if="payFile === null"
                  elevation
                  color="info"
                  class="pl-2 mt-2"
                  @click="$refs.payFileInput.value=null, $refs.payFileInput.click()"
                >
                  <v-icon>mdi-paperclip</v-icon>전표첨부
                </v-btn>
                <v-chip
                  v-else
                  class="mt-2 flex-shrink-0"
                  label
                  @click="payFile = null"
                  @click:close="payFile = null"
                  link
                  close
                >
                  {{ payFile.name }}
                </v-chip>
                <div class="d-flex justify-end mt-3">
                  <v-btn
                    elevation
                    color="primary"
                    :disabled="payerName.length <= 0 || payPrice <= 0"
                    @click="paymentAppend('card')"
                  >추가</v-btn>
                  <v-btn
                    elevation
                    @click="paymentAppenderInit"
                    class="ml-2"
                  >취소</v-btn>
                </div>
              </v-card>
            </div>
          </div>
        </div>
        <!-- 환불합계 시작 -->
        <div class="content-row align-start">
          <p class="content-tit mr-4">
            환불합계
          </p>
          <div class="item-wrap fix-width">
            <div
              class="item-row total"
              v-if="item.status === 0 && item.originSale.status === 1"
            >
              <div>
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1"
                  small
                >예상환불일</v-chip>
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="question ml-1"
                      small
                      color="warning"
                      v-on="on"
                      v-bind="attrs"
                    >mdi-help-circle-outline</v-icon>
                  </template>
                  <span>
                    일할계산합계를 미리 계산하기기 위한 값입니다.<br />
                    ※ 실제 환불일은 담당자가 승인시 지정한 날짜로 적용됩니다.
                  </span>
                </v-tooltip>
              </div>
              <div class="text-input-wrap ml-2">
                <v-menu
                  v-model="estDatePicker"
                  :close-on-content-click="false"
                  :nudge-left="90"
                  origin="right top"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="estDate"
                      label="예상환불일"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      hide-details
                      color="black"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="estDate"
                    :min="time.makeLocalTime(item.originSale.date, 'date')"
                    :max="
                      time.makeLocalTime(
                        time.moment(time.makeLocalTime(item.originSale.date))
                        .add(item.originSale.productTerm, 'M')
                        .add(-1, 'days'), 'date'
                      )
                    "
                    @input="estDatePicker = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div
              class="item-row total"
              v-if="item.status === 0 && item.originSale.status === 1"
            >
              <div>
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1"
                  small
                >일할계산합계</v-chip>
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="question ml-1"
                      small
                      color="warning"
                      v-on="on"
                      v-bind="attrs"
                    >mdi-help-circle-outline</v-icon>
                  </template>
                  <span>
                    기존매출정보와 예상환불일을 기준으로 일할계산된 금액입니다.<br />
                    ※ 실제 환불액은 계좌환불/카드취소건의 합으로 집계됩니다.
                  </span>
                </v-tooltip>
              </div>
              <span class="total-data d-flex align-center">
                {{ comm.priceToString(getEstRefundAmount(estDate).refundPrice) }}
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="question ml-1"
                      small
                      color="warning"
                      v-on="on"
                      v-bind="attrs"
                    >mdi-help-circle-outline</v-icon>
                  </template>
                  <span>
                    서비스시작일: {{ getEstRefundAmount(estDate).saleDate }}<br />
                    일할계산종료일: {{ getEstRefundAmount(estDate).endDate }}<br />
                    예상환불일: {{ getEstRefundAmount(estDate).date }}<br />
                    이용일수: {{ getEstRefundAmount(estDate).diffDays }}일<br />
                    일할계산잔여일수: {{ getEstRefundAmount(estDate).remainDays }}일<br />
                    일할계산(1일): {{
                      comm.priceToString(getEstRefundAmount(estDate).dayPrice)
                    }}<br />
                    일할계산(합계): {{
                      comm.priceToString(getEstRefundAmount(estDate).refundPrice)
                    }}<br />
                  </span>
                </v-tooltip>
              </span>
            </div>
            <div
              class="item-row total"
              v-else-if="item.status === 1 && item.originSale.serviceStart != null"
            >
              <div>
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1"
                  small
                >일할계산합계</v-chip>
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="question ml-1"
                      small
                      color="warning"
                      v-on="on"
                      v-bind="attrs"
                    >mdi-help-circle-outline</v-icon>
                  </template>
                  <span>
                    기존매출정보와 환불일을 기준으로 일할계산된 금액입니다.<br />
                    ※ 실제 환불액은 계좌환불/카드취소건의 합으로 집계됩니다.
                  </span>
                </v-tooltip>
              </div>
              <span class="total-data d-flex align-center">
                {{ comm.priceToString(getEstRefundAmount(item.date).refundPrice) }}
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="question ml-1"
                      small
                      color="warning"
                      v-on="on"
                      v-bind="attrs"
                    >mdi-help-circle-outline</v-icon>
                  </template>
                  <span>
                    서비스시작일: {{ getEstRefundAmount(item.date).saleDate }}<br />
                    일할계산종료일: {{ getEstRefundAmount(item.date).endDate }}<br />
                    환불일: {{ getEstRefundAmount(item.date).date }}<br />
                    이용일수: {{ getEstRefundAmount(item.date).diffDays }}일<br />
                    일할계산잔여일수: {{ getEstRefundAmount(item.date).remainDays }}일<br />
                    일할계산(1일): {{
                      comm.priceToString(getEstRefundAmount(item.date).dayPrice)
                    }}<br />
                    일할계산(합계): {{
                      comm.priceToString(getEstRefundAmount(item.date).refundPrice)
                    }}<br />
                  </span>
                </v-tooltip>
              </span>
            </div>
            <div class="item-row total pt-2">
              <v-chip
                outlined
                label
                class="pl-1 pr-1"
                small
              >
                {{ item.type === 0 ? '매출' : '환불' }}총액
              </v-chip>
              <span
                class="total-data bold"
                v-if="item.payments != undefined && item.payments.length > 0"
              >
                {{
                  comm.priceToString(
                    item.payments.reduce((acc, r) => {
                      if (r.status === 1) {
                        acc += r.amount;
                      }
                      return acc;
                    }, 0) * -1
                  )
                }}
              </span>
              <span
                class="total-data bold"
                v-else
              >0 원</span>
            </div>
          </div>
        </div>
        <!-- 환불합계 끝 -->
      </div>
      <!-- 환불인 경우 끝 -->

      <div class="bottom-wrap">
        <div class="memo-wrap">
          <p class="content-tit">
            <v-icon
              color="primary"
              class="mr-1 mt-1"
            >
              mdi-message-processing-outline
            </v-icon>
            댓글
            <v-chip
              v-if="item.memos != undefined"
              :color="item.memos.length > 0?'primary':''"
              x-small
              class="ml-1 pl-2 pr-2"
            >
              {{ item.memos.length }}
            </v-chip>
          </p>
          <div
            class="memo-list"
            v-if="item.memos != undefined && item.memos.length > 0"
          >
            <div
              class="memo"
              v-for="(memo, index) in item.memos"
              :key="index"
            >
              <div class="memo-top">
                <div class="text">
                  <v-chip
                    label
                    small
                    class="pr-2 pl-2"
                  >
                    {{
                      `${comm.getGroupFullPath(groups, memo.creatorGroupNo)} ${memo.creatorName}`
                    }}
                  </v-chip>
                  <v-icon
                    color="primary"
                    v-if="memo.receiver != null"
                  >mdi-arrow-right-thin</v-icon>
                  <v-chip
                    label
                    color="primary"
                    v-if="memo.receiver != null"
                    small
                    class="pr-2 pl-2"
                  >
                    {{
                      `${comm.getGroupFullPath(groups, memo.receiverGroupNo)}
                        ${memo.receiverName}`
                    }}
                  </v-chip>
                  <span class="date ml-2">{{ time.makeLocalTime(memo.created) }}</span>
                </div>
                <v-chip
                  v-if="memo.files.length > 0"
                  color="info"
                  small
                  close
                  close-icon="mdi-arrow-collapse-down"
                  link
                  @click="fileDownload(memo.files[0])"
                  @click:close="fileDownload(memo.files[0])"
                >
                  <span class="chip-max-width">
                    {{ memo.files[0].name }}
                  </span>
                </v-chip>
              </div>
              <p class="memo-body">{{ memo.content }}</p>
            </div>
          </div>
          <div
            class="memo-write-wrap"
            v-if="item.disabled !== 1"
          >
            <div class="left-wrap">
              <div class="receiver-file-wrap">
                <v-chip
                  small
                  class="mr-1"
                  v-if="memoReceiver === null"
                >수신인 없음</v-chip>
                <v-chip
                  small
                  class="mr-1"
                  v-else
                  color="warning"
                  @click="memoReceiver = null"
                  link
                  close
                  @click:close="memoReceiver = null"
                >
                  {{ memoReceiver.fullName }}
                </v-chip>
                <v-chip
                  small
                  class="mr-1"
                  v-if="memoFile === null"
                >첨부파일 없음</v-chip>
                <v-chip
                  small
                  class="mr-1"
                  v-else
                  color="info"
                  @click="memoFile = null"
                  @click:close="memoFile = null"
                  link
                  close
                >
                  {{ memoFile.name }}
                </v-chip>
              </div>
              <v-textarea
                solo
                hide-details
                no-resize
                class="area"
                style="font-size:0.9rem"
                flat
                v-model="memoContent"
                value="댓글내용을 입력해주세요."
                rows="3"
              />
            </div>
            <div class="btn-wrap">
              <v-card
                class="staffs-picker"
                v-click-outside="pickerInit"
                v-if="receiverPicker === true"
              >
                <v-text-field
                  :autofocus="true"
                  dense
                  flat
                  hide-details
                  v-model="staffsText"
                  placeholder="수신인 검색"
                  @input="staffsFiltering"
                  color="black"
                  outlined
                  solo
                ></v-text-field>
                <v-list v-if="staffsFilter.length > 0">
                  <v-list-item
                    v-for="(row, i) in staffsFilter"
                    :key="i"
                    dense
                    nav
                    link
                    @click="setReceiver(row)"
                  >
                    <v-list-item-title>
                      <v-chip
                        small
                        class="pr-1 pl-1 mr-1"
                        link
                        outlined
                        label
                      >
                        {{ row.group }}
                      </v-chip>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="staffsFilter.length === 0">
                  <v-list-item dense>
                    <v-list-item-title>{{
                      staffsText == null
                      || staffsText.trim().length === 0
                      ?'검색어를 입력해주세요.'
                      :'데이터가 없습니다.'
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    depressed
                    color="warning"
                    fab
                    small
                    :disabled="receiverPicker === true || memoReceiver !== null"
                    v-on="on"
                    v-bind="attrs"
                    @click="getStaffsList(true, 'receiverPicker')"
                  >
                    <v-icon>mdi-account-multiple-plus</v-icon>
                  </v-btn>
                </template>
                <span>수신인 선택</span>
              </v-tooltip>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    depressed
                    color="info"
                    fab
                    small
                    @click="$refs.memofileInput.value=null, $refs.memofileInput.click()"
                    v-on="on"
                    v-bind="attrs"
                    class="mt-1"
                    :disabled="memoFile !== null"
                  >
                    <v-icon>mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>파일첨부</span>
              </v-tooltip>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    depressed
                    class="mt-1"
                    fab
                    small
                    @click="memoRegist"
                    v-on="on"
                    v-bind="attrs"
                    :disabled="memoContent === null || memoContent === ''"
                  >
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </template>
                <span>댓글등록</span>
              </v-tooltip>
            </div>
            <input
              type="file"
              @change="fileSet($event, 'memoFile')"
              class="file-input"
              ref="memofileInput"
            >
          </div>
        </div>
        <div class="visit-wrap">
          <p class="content-tit">
            <v-icon
              color="primary"
              class="mr-1 mt-1"
            >
              mdi-clock-time-two-outline
            </v-icon>
            방문기록
          </p>
          <div
            class="visit"
            :class="item.memos.length > 0 && item.disabled !== 1?'on':''"
            v-if="item.visitor != undefined && item.visitor.length > 0"
          >
            <p
              v-for="(row, index) in item.visitor"
              :key="index"
            >
              {{ comm.getGroupFullPath(groups, row.groupNo, '')}} {{ row.name }}
              <span>{{ time.makeLocalTime(row.created) }}</span>
            </p>
          </div>
          <div
            class="visit-msg"
            v-else
          >방문기록이 없습니다.</div>
        </div>
      </div>
    </v-card>
    <v-dialog
      v-model="confirmDlg.show"
      width="500px"
    >
      <div class="date-picker-top">
        <p class="tit">
          <span v-if="item.status === 1">
            {{ item.type === 0 ? '매출' : '환불' }}일 변경
          </span>
          <span v-else>
            {{ item.type === 0 ? '매출' : '환불' }} 승인
          </span>
        </p>
        <v-chip
          small
          class="ml-2"
        >
          {{ item.type === 0 ? '매출' : '환불' }}번호: {{ item.no }}
        </v-chip>
      </div>

      <!-- 매출인 경우 -->
      <div
        class="confirm-picker-body"
        v-if="item.type === 0"
      >
        <!-- 승인인 경우만 (판매가 등...) -->
        <div v-if="confirmDlg.toStatus === 1">
          <div class="section">
            <p class="tit">
              판매가
            </p>
            <div class="sub ml-2">
              {{ comm.priceToString((item.productPrice * item.quantity) + item.discount) }}
            </div>
          </div>
          <div class="section">
            <p class="tit">
              결제총액
            </p>
            <div class="sub ml-2">
              {{ comm.priceToString(item.amount) }}
            </div>
          </div>
          <div class="section">
            <p class="tit">
              결제차액
            </p>
            <div
              class="sub ml-2"
              :class="
                item.amount - (
                  (item.productPrice * item.quantity) + item.discount
                ) < 0 ? 'error--text' : ''
              "
            >
              {{
                comm.priceToString(
                  item.amount - ((item.productPrice * item.quantity) + item.discount)
                )
              }}
            </div>
          </div>
          <div class="section">
            <p class="tit">
              매출합계
            </p>
            <div class="sub ml-2">
              {{ comm.priceToString(item.amount) }}
            </div>
          </div>
          <div
            class="section"
            v-if="item.productType === 0"
          >
            <p class="tit">
              서비스이용기간
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  해당 기간동안 서비스가 제공됩니다.<br />
                  즉시 제공을 원하시면 오늘 날짜({{ time.moment().format('YYYY-MM-DD') }})로 지정해주세요.
                </span>
              </v-tooltip>
            </p>
            <div class="sub ml-2">
              {{ serviceStartDate }}
              <v-menu
                :close-on-content-click="true"
                :nudge-left="90"
                origin="right top"
                transition="scale-transition"
                absolute
                offset-overflow
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon size="18px">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="serviceStartDate"
                  @input="setServiceEndDate"
                ></v-date-picker>
              </v-menu>
              ~
              <span class="ml-2">{{ serviceEndDate }}</span>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question ml-1"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  종료일은 시작일을 기점으로 자동계산되어 적용됩니다.<br />
                  시작일 + 상품이용기간({{ item.productTerm + item.productFreeTerm }}개월)
                </span>
              </v-tooltip>
            </div>
          </div>
          <div
            class="section"
            v-if="item.productType === 0"
          >
            <p class="tit">
              일할계산종료일
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  일할계산종료일: 서비스시작일 + 상품이용기간<br />
                  ※ 상품무료이용기간은 환불시 일할계산에 포함되지 않습니다.
                </span>
              </v-tooltip>
            </p>
            <div class="sub ml-2">
              {{ time.moment(serviceStartDate).add(item.productTerm, 'M').format('YYYY-MM-DD') }}
            </div>
          </div>
        </div>
        <!-- 매출일 -->
        <div class="section">
          <p class="tit">
            매출일
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="question"
                  small
                  color="warning"
                  v-on="on"
                  v-bind="attrs"
                >mdi-help-circle-outline</v-icon>
              </template>
              <span>
                영업담당자의 인센티브 정산이 적용되는 날짜입니다.<br />
              </span>
            </v-tooltip>
          </p>
          <div class="sub ml-2">
            {{ time.makeLocalDate(confirmDate, 'date') }}
            <v-menu
              :close-on-content-click="true"
              :nudge-left="90"
              origin="right top"
              transition="scale-transition"
              absolute
              offset-overflow
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon size="18px">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-date-picker v-model="confirmDate"></v-date-picker>
            </v-menu>
          </div>
        </div>
        <div class="section d-flex justify-end pb-0 pr-0">
          <v-btn
            elevation
            color="primary"
            v-if="confirmDlg.toStatus === 1"
            @click="updateSalesStatus(1)"
          >
            매출 승인
          </v-btn>
          <v-btn
            elevation
            color="primary"
            @click="updateDate()"
            v-else
          >
            매출일 변경
          </v-btn>
        </div>
      </div>
      <!-- 매출인 경우 끝 -->

      <!-- 환불인 경우 -->
      <div
        class="confirm-picker-body"
        v-else-if="
          item.type === 1 && item.originSale !== undefined
        "
      >
        <!-- 환불승인인 경우만 -->
        <div v-if="confirmDlg.toStatus === 1">
          <div class="section">
            <p class="tit">
              기존매출액
            </p>
            <div class="sub ml-2">
              {{ comm.priceToString(item.originSale.amount) }}
            </div>
          </div>
          <div
            class="section"
            v-if="item.originSale.serviceStart !== null"
          >
            <p class="tit">
              서비스시작일
            </p>
            <div class="sub ml-2">
              {{ getEstRefundAmount(confirmDate).saleDate }}
            </div>
          </div>
          <div class="section">
            <p class="tit">
              환불승인일
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  영업담당자의 인센티브 정산이 적용되는 날짜입니다.<br />
                  ※ 고객의 서비스는 환불승인일과 별개로 즉시 중단됩니다.
                </span>
              </v-tooltip>
            </p>
            <div class="sub ml-2">
              {{ time.makeLocalDate(confirmDate, 'date') }}
              <v-menu
                :close-on-content-click="true"
                :nudge-left="90"
                origin="right top"
                transition="scale-transition"
                absolute
                offset-overflow
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon size="18px">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="confirmDate"></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div
            class="section"
            v-if="item.originSale.serviceStart !== null"
          >
            <p class="tit">
              환불금액
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question ml-1"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  기존매출정보와 환불승인일을 기준으로 일할계산된 금액과 위약금을 계산한 금액입니다.<br />
                  ※ 실제 환불액은 계좌환불/카드취소건의 합으로 집계됩니다.
                </span>
              </v-tooltip>
            </p>
            <div class="sub ml-2">
              {{ comm.priceToString(getEstRefundAmount(confirmDate).refundPrice) }}
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question ml-1"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  서비스시작일: {{ getEstRefundAmount(confirmDate).saleDate }}<br />
                  일할계산종료일: {{ getEstRefundAmount(confirmDate).endDate }}<br />
                  환불일: {{ getEstRefundAmount(confirmDate).date }}<br />
                  이용일수: {{ getEstRefundAmount(confirmDate).diffDays }}일<br />
                  일할계산잔여일수: {{ getEstRefundAmount(confirmDate).remainDays }}일<br />
                  일할계산(1일): {{
                    comm.priceToString(getEstRefundAmount(confirmDate).dayPrice)
                  }}<br />
                  위약금(10%): {{
                    comm.priceToString(getEstRefundAmount(sale).penaltyPrice)
                  }}<br />
                  일할계산/위약금(계산값): {{
                    comm.priceToString(getEstRefundAmount(confirmDate).refundPrice)
                  }}<br />
                </span>
              </v-tooltip>
            </div>
          </div>
          <div class="section">
            <p class="tit">
              환불총액
            </p>
            <div class="sub ml-2">
              {{ comm.priceToString(item.amount * -1) }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="section">
            <p class="tit">
              환불승인일
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  영업담당자의 인센티브 정산이 적용되는 날짜입니다.<br />
                </span>
              </v-tooltip>
            </p>
            <div class="sub ml-2">
              {{ time.makeLocalDate(confirmDate, 'date') }}
              <v-menu
                :close-on-content-click="true"
                :nudge-left="90"
                origin="right top"
                transition="scale-transition"
                absolute
                offset-overflow
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon size="18px">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="confirmDate"></v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
        <div class="section justify-end pb-0 pr-0">
          <v-btn
            elevation
            color="primary"
            v-if="confirmDlg.toStatus === 1"
            @click="updateRefundStatus(1)"
          >
            환불 승인
          </v-btn>
          <v-btn
            elevation
            color="primary"
            @click="updateDate()"
            v-else
          >
            환불일 변경
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <dlg-history
      :show.sync="historyDlg.show"
      :data="historyDlg.data"
      :title="historyDlg.title"
    />
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import comm from '@/util/comm';
import time from '@/util/time';
import fileUtils from '@/util/file';
import DlgHistory from '@/components/dialog/DlgHistory.vue';
import PriceText from '../input/PriceText.vue';

export default {
  components: {
    PriceText,
    DlgHistory,
  },
  name: 'DlgSalesView',
  props: {
    item: {
      type: Object,
    },
    show: {
      type: Boolean,
      required: true,
    },
    func: {
      type: Function,
    },
  },
  data: () => ({
    time,
    comm,
    priceRule: (value) => {
      const val = Number(value.replaceAll(',', ''));
      if (val < 0 || val > 1000000000 || value === 'NaN') {
        return '0원 이상 10억 미만이어야 합니다.';
      }
      return true;
    },
    numberLengthRule: (value, leng) => {
      if (value === null || Number.isNaN(Number(value)) === true || value.length !== leng) {
        return `${leng}자리의 숫자로만 입력해주세요.`;
      }
      return true;
    },
    installmentRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true || value > 48 || value < 0) {
        return '0 ~ 48 사이의 숫자로 입력해주세요.';
      }
      return true;
    },
    phoneNumberRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true
        || value.length > 11 || value.length < 10) {
        return '10자리 혹은 11자리의 숫자로만 입력해주세요.';
      }
      return true;
    },
    bankAccountRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true
        || value.length > 16 || value.length < 10) {
        return '10 ~ 16자리의 숫자로만 입력해주세요.';
      }
      return true;
    },
    quantityRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true || value > 9999 || value <= 0) {
        return '1 ~ 9999 사이의 숫자로 입력해주세요.';
      }
      return true;
    },
    receiverPicker: false,
    fileUtils,
    saleFile: null,
    memoContent: null,
    memoFile: null,
    memoReceiver: null,
    groups: [],
    staffsText: null,
    staffs: [],
    staffsFilter: [],
    groupText: null,
    groupFilter: [],
    groupList: [],
    groupPicker: false,
    historyDlg: {
      show: false,
      data: [],
      title: '',
      sub: null,
    },
    pg: {
      data: [],
      value: null,
    },
    company: {
      data: ['NH농협', '국민', '농협', '롯데', '비씨', '삼성', '신한', '씨티', '우리', '전북', '하나', '현대'],
      value: 'NH농협',
    },
    cardType: {
      data: [
        { name: 'SMS인증', value: 0 },
        { name: '수기결제', value: 1 },
      ],
      value: 0,
    },
    cardInfo: {
      cardNumber: null,
      validThru: null,
      installment: null,
      password: null,
      phone: null,
      birthday: null,
    },
    bank: {
      data: [
        'KEB하나은행',
        'SC제일은행',
        '국민은행',
        '신한은행',
        '외환은행',
        '우리은행',
        '한국시티은행',
        '경남은행',
        '광주은행',
        '대구은행',
        '부산은행',
        '전북은행',
        '제주은행',
        '기업은행',
        '농협',
        '수협',
        '한국산업은행',
        '한국수출입은행',
        '기타',
      ],
      value: 'KEB하나은행',
    },
    bankAccount: null,
    paymentAppender: false,
    paymentBankAppender: false,
    payDatePicker: false,
    payDate: time.moment().format('YYYY-MM-DD'),
    payerName: '',
    payPrice: 0,
    payFile: null,
    managerPicker: false,
    discountUpdateValue: 0,
    discountUpdateRadio: -1,
    discountUpdatePicker: false,
    quantityUpdateValue: 0,
    quantityUpdatePicker: false,
    confirmDlg: {
      show: false,
      toStatus: 0,
    },
    confirmDate: time.moment().format('YYYY-MM-DD'),
    serviceStartDate: time.moment().format('YYYY-MM-DD'),
    serviceEndDate: time.moment().format('YYYY-MM-DD'),
    payUpdateNo: null,
    payUpdateOriginFileNo: null,
    makeHistories: {
      status: (data, vue) => {
        const result = [];
        const thisType = vue.item.type;
        const msgObj = {
          0: '미승인',
          1: '승인',
          2: '가결제',
          3: thisType === 1 ? '환불일 변경' : '매출일 변경',
        };
        data.forEach((r) => {
          if (r.after.status !== undefined || r.after.date !== undefined) {
            const msgKey = r.after.status !== undefined ? r.after.status : 3;
            let msg = msgObj[String(msgKey)];
            if (r.after.date !== undefined) {
              msg += `[${time.makeLocalDate(r.after.date, 'date')}]`;
            }
            result.push({ ...r, after: msg });
          }
        });
        return result;
      },
      manager: (data, staffs) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.manager !== undefined) {
            const { fullName } = staffs.find((row) => r.after.manager === row.id);
            result.push({ ...r, after: `${fullName}` });
          }
        });
        return result;
      },
      file: (data) => {
        const result = [];
        data.forEach((r) => {
          const after = r.after.action === 'delete' ? '삭제' : r.after.fileName;
          result.push({ ...r, after });
        });
        return result;
      },
      discount: (data) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.discount !== undefined) {
            result.push({ ...r, after: comm.priceToString(r.after.discount) });
          }
        });
        return result;
      },
      quantity: (data) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.quantity !== undefined) {
            result.push({ ...r, after: r.after.quantity });
          }
        });
        return result;
      },
      paymentInfo: (data) => {
        const result = [];
        const cardTypeArr = ['수기결제', 'SMS인증'];
        const logTypeObj = {
          add: '등록',
          confirm: '승인',
          cancle_confirm: '승인취소',
          delete: '삭제',
          update: '수정',
        };
        data.forEach((r) => {
          if (r.after.paymentInfo !== undefined) {
            const cardType = r.after.paymentInfo.cardType !== undefined
              ? cardTypeArr[r.after.paymentInfo.cardType] : '';
            result.push({
              ...r,
              after: `
                [${logTypeObj[r.after.logType]}]
                ${r.after.pgName} 
                ${cardType}
                ${r.after.payer} 
                ${comm.priceToString(r.after.amount)}
              `,
              paymentInfo: r.after.paymentInfo,
            });
          }
        });
        return result;
      },
      paymentRefundBank: (data) => {
        const result = [];
        const logTypeObj = {
          add: '등록',
          confirm: '입금완료',
          cancle_confirm: '환불요청',
          delete: '삭제',
          update: '수정',
        };
        data.forEach((r) => {
          if (r.after.paymentInfo.type === 'bank') {
            result.push({
              ...r,
              after: `
                [${logTypeObj[r.after.logType]}]
                ${r.after.paymentInfo.bank}
                ${r.after.paymentInfo.account}
                ${r.after.payer}
                ${comm.priceToString(r.after.amount * -1)}
              `,
              paymentInfo: r.after.paymentInfo,
            });
          }
        });
        return result;
      },
      paymentRefundCard: (data) => {
        const result = [];
        const logTypeObj = {
          add: '등록',
          confirm: '취소완료',
          cancle_confirm: '취소요청',
          delete: '삭제',
          update: '수정',
        };
        data.forEach((r) => {
          if (r.after.paymentInfo.type === 'card') {
            result.push({
              ...r,
              after: `
                [${logTypeObj[r.after.logType]}]
                ${r.after.paymentInfo.company}
                ${r.after.payer}
                ${comm.priceToString(r.after.amount * -1)}
              `,
              paymentInfo: r.after.paymentInfo,
            });
          }
        });
        return result;
      },
      group: (data, vue) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.groupNo !== undefined) {
            result.push({ ...r, after: `${comm.getGroupFullPath(vue.groups, r.after.groupNo)}` });
          } else if (r.after.group !== undefined) {
            result.push({ ...r, after: `${comm.getGroupFullPath(vue.groups, r.after.group)}` });
          }
        });
        return result;
      },
    },
    estDate: time.moment().format('YYYY-MM-DD'),
    estDatePicker: false,
    currentRooms: null,
  }),
  computed: {
    getItemData: () => this.item,
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.$emit('update:show', false);
      this.memoContent = null;
      this.memoReceiver = null;
      this.memoFile = null;
      this.estDatePicker = false;
      this.estDate = time.moment().format('YYYY-MM-DD');
      this.paymentAppenderInit();
      this.pickerInit();
    },
    staffsFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.staffs.filter((r) => r.fullName.indexOf(e) !== -1);
        this.staffsFilter = result;
      } else {
        this.staffsFilter = [];
      }
    },
    groupFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.groupList.filter((r) => r.name.indexOf(e) !== -1);
        this.groupFilter = result;
      } else {
        this.groupFilter = [];
      }
    },
    getStaffsList(isPicker = false, target = null) {
      this.staffs = [];
      this.staffsFilter = [];
      this.staffsText = null;
      if (isPicker === true) this.progress(true);
      this.$socket.emit('users.staffs.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          resp.items.forEach((r) => {
            const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
            const user = {
              id: r.id,
              fullName: `${groupName}${r.name}`,
              name: r.name,
              group: groupName,
            };
            this.staffs.push(user);
            if (isPicker === true) {
              this.progress(false);
              this[target] = true;
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          if (isPicker === true) this.progress(false);
        }
      });
    },
    getGroupList(target = null) {
      this.progress(true);
      this.groupText = null;
      this.groupFilter = [];
      this.groupList = [];
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
          this.groupList = comm.makeGroupList(resp.items.groups);
          this.progress(false);
          if (target !== null) this[target] = true;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    setReceiver(staff = null) {
      this.memoReceiver = staff;
      this.receiverPicker = false;
    },
    updateManager(staff) {
      const func = () => {
        this.updateSales({
          no: this.item.no,
          salesManager: staff.id,
        }, '영업담당자가 변경되었습니다.');
      };
      this.confirm({
        show: true,
        func,
        msg: `영업담당자를 ${staff.fullName}님 으로 변경할까요?<br/><br/>※변경한 영업담당자의 실적으로 집계됩니다.`,
        btnText: '변경',
        color: 'error',
      });
    },
    updateGroup(group) {
      const func = () => {
        this.updateSales({
          no: this.item.no,
          groupNo: group.value,
        }, '영업그룹이 변경되었습니다.');
      };
      this.confirm({
        show: true,
        func,
        msg: `영업그룹을 '${group.name}' 그룹으로 변경할까요?<br/><br/>※변경한 영업그룹의 실적으로 집계됩니다.`,
        btnText: '변경',
        color: 'error',
      });
    },
    updateDiscount() {
      const discount = this.discountUpdateRadio * Number(this.discountUpdateValue);
      if ((this.item.productPrice * this.item.quantity) + discount < 0) {
        this.alert(['error', '할인금액을 판매가보다 작거나 같게 입력해주세요.']);
      } else {
        this.updateSales({
          no: this.item.no,
          discount,
        }, '판매가조정이 적용되었습니다.');
      }
    },
    updateQuantity() {
      if (Number(this.quantityUpdateValue) >= 1 && Number(this.quantityUpdateValue) < 9999) {
        this.updateSales({
          no: this.item.no,
          quantity: Number(this.quantityUpdateValue),
        }, '수량 변경이 적용되었습니다.');
      } else {
        this.alert(['error', '수량을 1 ~ 9999 사이의 숫자로 입력해주세요.']);
      }
    },
    updateSales(obj, msg) {
      this.progress(true);
      this.$socket.emit('sales.update', obj, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', msg]);
          this.contentsRefresh();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    updatePayments(obj, msg) {
      this.progress(true);
      this.$socket.emit('payments.update', {
        ...obj,
        saleNo: this.item.no,
      }, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', msg]);
          this.contentsRefresh();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    async fileSet(e, target) {
      this.progress(true);
      if (e.target.files.length > 1) {
        this.alert(['error', '첨부파일은 1개만 등록가능합니다.']);
      } else if (e.target.files[0].type.indexOf('image') === -1
        && e.target.files[0].type.indexOf('pdf') === -1
        && target !== 'memoFile') {
        this.alert(['error', '이미지 파일 및 PDF 파일만 업로드 가능합니다.']);
      } else if (e.target.files.length > 0) {
        const sizeCheck = e.target.files[0].size < 10485760;
        if (sizeCheck === true) {
          const getFiles = await this.fileUtils.readFileToPayload(e.target.files[0]);
          if (target === 'payUpdateFile') {
            this.payUpdateFileFunc(getFiles);
          } else {
            this[target] = getFiles;
          }
          if (target === 'saleFile') this.updateDocFunc();
        } else {
          this.alert(['error', '파일당 최대 10MB 까지 업로드 가능합니다.']);
        }
      }
      this.progress(false);
    },
    memoRegist() {
      this.progress(true);
      const reqObj = {
        saleNo: this.item.no,
        content: this.memoContent,
        tag: this.memoReceiver !== null ? [this.memoReceiver.id] : [],
        files: this.memoFile !== null ? [this.memoFile] : [],
      };
      this.$socket.emit('sales.memo.add', reqObj, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', '댓글이 등록되었습니다.']);
          this.memoContent = null;
          this.memoReceiver = null;
          this.memoFile = null;
          this.contentsRefresh();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    pickerInit() {
      this.receiverPicker = false;
      this.managerPicker = false;
      this.staffsText = null;
      this.staffsFilter = [];
      this.discountUpdateValue = 0;
      this.discountUpdateRadio = -1;
      this.discountUpdatePicker = false;
      this.quantityUpdateValue = 0;
      this.quantityUpdatePicker = false;
      this.confirmDlg = {
        show: false,
        toStatus: 0,
      };
      this.confirmDate = this.time.moment().format('YYYY-MM-DD');
      this.serviceStartDate = this.time.moment().format('YYYY-MM-DD');
      this.serviceEndDate = this.time.moment().format('YYYY-MM-DD');
      this.groupText = null;
      this.groupFilter = [];
      this.groupList = [];
      this.groupPicker = false;
    },
    fileDownload(fileObj) {
      this.progress(true);
      this.$socket.emit('files.get', {
        no: fileObj.no,
        token: fileObj.token,
      }, async (resp) => {
        if (resp.result === 'success') {
          await fileUtils.writeFileFromPayload(resp.item);
          this.progress(false);
        } else if (resp.name === 'TokenExpiredError') {
          this.alert(['error', '파일 다운로드 시간이 만료되었습니다. 새로고침 후 다시 시도해주세요.']);
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    updatePaymentsStatus(payment, type = null) {
      const toStatus = payment.status === 0 ? 1 : 0;
      let typeStr = '결제건';
      let actionArr = ['미승인', '승인'];
      const verbArr = ['으로 변경되었습니다.', '처리되었습니다.'];
      if (type === 'bank') {
        typeStr = '계좌환불건';
        actionArr = ['환불요청', '입금완료'];
      } else if (type === 'card') {
        typeStr = '카드취소건';
        actionArr = ['취소요청', '취소완료'];
      }
      const func = () => {
        this.updatePayments({
          no: payment.no,
          status: toStatus,
        }, `${actionArr[toStatus]} ${verbArr[toStatus]}`);
      };
      if (toStatus === 1 && payment.files.length <= 0) {
        this.confirm({
          show: true,
          msg: `전표가 등록되지 않은 ${typeStr}입니다.
            <br/><br/>정말 ${actionArr[toStatus]}처리할까요?`,
          btnText: `${actionArr[toStatus]}처리`,
          color: 'primary',
          func,
        });
      } else if (toStatus === 0) {
        this.confirm({
          show: true,
          msg: `${actionArr[toStatus]} ${typeStr}은 총액에 포함되지 않습니다.<br/><br/>정말 변경할까요?`,
          btnText: `${actionArr[toStatus]}으로 변경`,
          color: 'primary',
          func,
        });
      } else {
        func();
      }
    },
    updateSalesStatus(toStatus) {
      const msgArr = [
        '매출 승인이 취소되었습니다.',
        '매출이 승인되었습니다.',
        '가결제 처리 되었습니다.',
      ];
      if (toStatus === 1) {
        const func = () => {
          this.progress(true);
          this.$socket.emit('rooms.list.get', {
            page: 1,
            itemsPerPage: 0,
            sortBy: ['no'],
            sortDesc: [true],
            filters: [{
              condition: 'eq',
              column: 'disabled',
              value: 0,
            }, {
              where: 'and',
              condition: 'eq',
              column: 'productNo',
              value: this.item.productNo,
            }],
          }, (resp) => {
            if (resp.result === 'success') {
              this.updateSales({
                no: this.item.no,
                start: this.serviceStartDate,
                end: this.serviceEndDate,
                status: toStatus,
                date: this.confirmDate,
                rooms: resp.items.map((room) => ({ no: room.no, name: room.name })),
              }, msgArr[toStatus]);
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.progress(false);
            }
          });
        };
        const leadingMsg = '<br/><br/>※ 고객에게 이미 서비스가 제공중이라면 해당 매출의 서비스로 대체됩니다.';
        const msg = '매출을 승인할까요?';
        this.confirm({
          show: true,
          msg: this.item.productType === 0 ? `${msg}${leadingMsg}` : msg,
          func,
          btnText: '매출 승인',
          color: 'success',
        });
      // 미승인으로 변경 (승인취소)
      } else if (toStatus === 0) {
        let msg = '고객에게로의 서비스 제공이 중지되며<br/>매출이 영업실적에서 제외됩니다.<br/><br/>정말 승인 취소 할까요?';
        if (this.item.status === 2 && this.item.productType === 0) {
          msg = '고객에게로의 서비스 제공이 중지됩니다.<br/><br/>미승인으로 변경할까요?';
        } else if (this.item.productType === 1) {
          msg = '매출이 영업실적에서 제외됩니다.<br/><br/>정말 승인 취소 할까요?';
        }
        this.confirm({
          show: true,
          msg,
          func: () => {
            this.updateSales({
              no: this.item.no,
              status: toStatus,
            }, msgArr[toStatus]);
          },
          btnText: this.item.status === 1 ? '승인 취소' : '미승인으로 변경',
          color: 'error',
        });
      } else if (toStatus === 2) {
        const msg = '가결제 상태인 동안 고객에게 서비스가 제공됩니다.<br/>(이미 서비스가 제공중이라면 대체됨)<br/><br/>정말 가결제 처리 할까요?';
        this.confirm({
          show: true,
          msg,
          func: () => {
            this.$socket.emit('rooms.list.get', {
              page: 1,
              itemsPerPage: 0,
              sortBy: ['no'],
              sortDesc: [true],
              filters: [{
                condition: 'eq',
                column: 'disabled',
                value: 0,
              }, {
                where: 'and',
                condition: 'eq',
                column: 'productNo',
                value: this.item.productNo,
              }],
            }, (response) => {
              if (response.result === 'success') {
                this.updateSales({
                  no: this.item.no,
                  start: time.moment().format('YYYY-MM-DD'),
                  status: toStatus,
                  rooms: response.items.map((room) => ({ no: room.no, name: room.name })),
                }, msgArr[toStatus]);
              } else {
                this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
                console.error(response);
                this.progress(false);
              }
            });
          },
          btnText: '가결제 처리',
          color: 'error',
        });
      } else {
        this.alert(['error', '오류가 발생했습니다.']);
        this.init();
      }
    },
    updateRefundStatus(toStatus) {
      const item = { ...this.item };
      const msgArr = [
        '환불 승인이 취소되었습니다.',
        '환불이 승인되었습니다.',
      ];
      if (toStatus === 1) {
        const amountDiff = this.getEstRefundAmount(
          this.confirmDate,
        ).refundPrice + Number(item.amount);
        const amountDiffMsg = '일할계산합계와 환불총액이 다릅니다.<br/>(실제 환불액은 환불총액으로 집계됨)<br/></br>';
        const msg = `
          ${amountDiff === 0 ? '' : amountDiffMsg}
          환불을 승인할까요?<br/><br/>※ 고객에게로의 서비스 제공이 즉시 중단되며 원복할 수 없습니다.
        `;
        this.confirm({
          show: true,
          msg,
          func: () => {
            this.updateSales({
              no: item.no,
              status: toStatus,
              date: this.confirmDate,
            }, msgArr[toStatus]);
          },
          btnText: '환불 승인',
          color: 'error',
        });
      } else if (toStatus === 0) {
        const msg = '환불 승인을 취소할까요?<br/><br/>※ 환불로 중단됬던 서비스는 원복되지 않습니다.';
        this.confirm({
          show: true,
          msg,
          func: () => {
            this.updateSales({
              no: item.no,
              status: toStatus,
            }, msgArr[toStatus]);
          },
          btnText: '승인 취소',
          color: 'error',
        });
      } else {
        this.alert(['error', '오류가 발생했습니다.']);
      }
    },
    updateDate() {
      this.confirm({
        show: true,
        msg: '영업담당자 인센티브 정산에 즉시 반영됩니다.<br/><br/>정말 변경할까요?',
        func: () => {
          this.updateSales({
            no: this.item.no,
            date: this.confirmDate,
          }, '변경되었습니다.');
        },
        btnText: '변경',
        color: 'primary',
      });
    },
    updateDisabled(no) {
      const func = () => {
        this.confirm({ show: false });
        this.$socket.emit('sales.update', { no, disabled: true }, (resp) => {
          if (resp.result === 'success') {
            this.alert(['success', '삭제되었습니다.']);
            this.contentsRefresh();
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      };
      this.confirm({
        show: true,
        func,
        msg: `삭제된 매출/환불건은 집계에서 제외되며 복구할 수 없습니다.
          <br/><br/>정말 삭제할까요?`,
        btnText: '삭제',
        color: 'error',
      });
    },
    contentsRefresh() {
      this.$socket.emit('sales.get', { no: this.item.no }, (resp) => {
        if (resp.result === 'success') {
          this.$emit('update:item', resp.item);
          this.confirm({ show: false });
          this.pickerInit();
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
          this.confirm({ show: false });
        }
      });
    },
    deletePayment(payment) {
      const msg = this.item.type === 1
        ? '삭제되었습니다.' : '결제건이 삭제되었습니다.';
      const func = () => {
        this.updatePayments({
          no: payment.no,
          disabled: true,
        }, msg);
      };
      this.confirm({
        show: true,
        msg: '삭제시 복구할 수 없습니다.<br/>정말 삭제할까요?',
        btnText: '삭제',
        color: 'error',
        func,
      });
    },
    paymentAppenderInit() {
      this.paymentAppender = false;
      this.payDatePicker = false;
      this.payDate = time.moment().format('YYYY-MM-DD');
      this.payerName = '';
      this.payPrice = 0;
      this.cardType.value = 0;
      this.company.value = 'NH농협';
      this.cardInfo = {
        cardNumber: null,
        validThru: null,
        installment: null,
        password: null,
        phone: null,
        birthday: null,
      };
      this.payFile = null;
      this.bank.value = 'KEB하나은행';
      this.bankAccount = null;
      this.paymentBankAppender = false;
    },
    getPgList(method = null) {
      this.pg = {
        data: [],
        value: null,
      };
      this.$socket.emit('pg.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          if (method === null) {
            this.pg.data = resp.items;
          } else {
            this.pg.data = resp.items.filter((r) => r.type === method);
          }
          this.pg.value = this.pg.data.find((r, i) => i === 0);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.paymentAppender = false;
        }
      });
    },
    paymentVaildChecker(type = null) {
      if (this.payerName.length <= 0 || this.payPrice <= 0) {
        return true;
      }
      if (
        type === 'refund'
        && (
          this.bankAccount === null
          || this.bankAccount.length > 16
          || this.bankAccount.length < 10
          || Number.isNaN(Number(this.bankAccount)) === true
        )
      ) {
        return true;
      }
      if (type === null && this.pg.value.type === 1) {
        if (this.cardInfo.installment === null
          || Number.isNaN(Number(this.cardInfo.installment)) === true
          || this.cardInfo.installment < 0
          || this.cardInfo.installment > 48) return true;
        if (this.cardInfo.phone === null
          || Number.isNaN(Number(this.cardInfo.phone)) === true
          || this.cardInfo.phone.length < 10
          || this.cardInfo.phone.length > 11) return true;
        if (this.cardType.value === 1) {
          if (this.cardInfo.cardNumber === null
            || Number.isNaN(Number(this.cardInfo.cardNumber)) === true
            || this.cardInfo.cardNumber.length !== 16) return true;
          if (this.cardInfo.validThru === null
            || Number.isNaN(Number(this.cardInfo.validThru)) === true
            || this.cardInfo.validThru.length !== 4) return true;
          if (this.cardInfo.password === null
            || Number.isNaN(Number(this.cardInfo.password)) === true
            || this.cardInfo.password.length !== 2) return true;
          if (this.cardInfo.birthday === null
            || Number.isNaN(Number(this.cardInfo.birthday)) === true
            || this.cardInfo.birthday.length !== 6) return true;
        }
      }
      return false;
    },
    paymentAppend(type = null) {
      const paymentInfo = {};
      const msgObj = {
        bank: '계좌환불이 추가되었습니다.',
        card: '카드취소가 추가되었습니다.',
      };
      const msg = type === null ? '결제건이 추가되었습니다.' : msgObj[type];
      let payItem = {};
      if (type === 'bank') {
        payItem = {
          saleNo: this.item.no,
          payer: this.payerName,
          amount: this.payPrice * -1,
          paymentInfo: {
            type: 'bank',
            bank: this.bank.value,
            account: Number(this.bankAccount),
          },
          files: this.payFile === null ? [] : [this.payFile],
        };
      } else if (type === 'card') {
        payItem = {
          saleNo: this.item.no,
          payer: this.payerName,
          amount: this.payPrice * -1,
          paymentInfo: {
            type: 'card',
            company: this.company.value,
          },
          files: this.payFile === null ? [] : [this.payFile],
        };
      } else {
        if (this.pg.value.type === 1) {
          paymentInfo.company = this.company.value;
          paymentInfo.phone = this.cardInfo.phone;
          paymentInfo.installment = Number(this.cardInfo.installment);
          paymentInfo.cardType = this.cardType.value;
          if (this.cardType.value === 1) {
            paymentInfo.birthday = this.cardInfo.birthday;
            paymentInfo.cardNumber = this.cardInfo.cardNumber;
            paymentInfo.validThru = this.cardInfo.validThru;
            paymentInfo.password = this.cardInfo.password;
          }
        }
        payItem = {
          saleNo: this.item.no,
          pg: this.pg.value.no,
          payer: this.payerName,
          amount: this.payPrice,
          date: this.payDate,
          paymentInfo,
          files: this.payFile === null ? [] : [this.payFile],
          pgName: this.pg.value.name,
          pgType: this.pg.value.type,
        };
      }
      const command = type === null
        ? 'payments.add' : 'payments.refund.add';
      this.progress(true);
      this.$socket.emit(command, payItem, (resp) => {
        if (resp.result === 'success') {
          this.contentsRefresh();
          this.paymentAppenderInit();
          this.alert(['success', msg]);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    getHistories(action, columnName, title) {
      this.progress(true);
      this.$socket.emit('log.get', {
        action,
        key: String(this.item.no),
      }, (resp) => {
        if (resp.result === 'success') {
          if (columnName === 'manager') {
            const staffs = [];
            this.$socket.emit('users.staffs.list.get', {
              page: 1,
              itemsPerPage: 0,
              sortBy: [],
              sortDesc: [],
              filters: [],
            }, (response) => {
              if (response.result === 'success') {
                response.items.forEach((r) => {
                  const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
                  const user = {
                    id: r.id,
                    fullName: `${groupName}${r.name}`,
                    name: r.name,
                    group: groupName,
                  };
                  staffs.push(user);
                });
                const filterData = this.makeHistories[columnName](resp.items, staffs);
                this.historyDlg.data = filterData;
                this.historyDlg.show = true;
                this.historyDlg.title = title;
                this.progress(false);
              } else {
                this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
                console.error(resp);
                this.progress(false);
              }
            });
          } else {
            const filterData = this.makeHistories[columnName](resp.items, this);
            this.historyDlg.data = filterData;
            this.historyDlg.show = true;
            this.historyDlg.title = title;
            this.progress(false);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    deleteDocFunc(fileNo) {
      const saleNo = this.item.no;
      const func = () => {
        this.progress(true);
        this.$socket.emit('sales.file.delete', { fileNo, saleNo }, (resp) => {
          if (resp.result === 'success') {
            this.contentsRefresh();
            this.alert(['success', '계약서가 삭제되었습니다.']);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
          }
        });
      };
      this.confirm({
        show: true,
        func,
        msg: '삭제시 복구할 수 없습니다.<br/>정말 삭제할까요?',
        btnText: '삭제',
        color: 'error',
      });
    },
    updateDocFunc() {
      const updateObj = {
        saleNo: this.item.no,
        file: this.saleFile,
      };
      const func = () => {
        this.progress(true);
        this.$socket.emit('sales.file.update', updateObj, (resp) => {
          if (resp.result === 'success') {
            const msg = this.item.file !== null ? '변경' : '첨부';
            this.contentsRefresh();
            this.alert(['success', `계약서가 ${msg}되었습니다.`]);
            this.saleFile = null;
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
            this.confirm({ show: false });
            this.saleFile = null;
          }
        });
      };
      if (this.item.file !== null) {
        updateObj.originFileNo = this.item.file.no;
        this.confirm({
          show: true,
          func,
          msg: '계약서 변경시 기존계약서는 삭제되며 복구할 수 없습니다.<br/><br/>정말 변경할까요?',
          btnText: '변경',
          color: 'error',
        });
      } else {
        func();
      }
    },
    payUpdateFileFunc(file) {
      const updateObj = {
        paymentNo: this.payUpdateNo,
        file,
      };
      const func = () => {
        this.progress(true);
        this.$socket.emit('payments.file.update', updateObj, (resp) => {
          if (resp.result === 'success') {
            const msg = this.payUpdateOriginFileNo !== null ? '변경' : '첨부';
            this.contentsRefresh();
            this.alert(['success', `전표가 ${msg}되었습니다.`]);
            this.payUpdateOriginFileNo = null;
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
            this.confirm({ show: false });
            this.payUpdateOriginFileNo = null;
          }
        });
      };
      if (this.payUpdateOriginFileNo !== null) {
        updateObj.originFileNo = this.payUpdateOriginFileNo;
        this.confirm({
          show: true,
          func,
          msg: '변경시 기존 전표는 삭제되며 복구할 수 없습니다.<br/><br/>정말 변경할까요?',
          btnText: '변경',
          color: 'error',
        });
      } else {
        func();
      }
    },
    deletePaymentFileFunc(paymentNo, fileNo) {
      const func = () => {
        this.progress(true);
        this.$socket.emit('payments.file.delete', { fileNo, paymentNo }, (resp) => {
          if (resp.result === 'success') {
            this.contentsRefresh();
            this.alert(['success', '결제전표가 삭제되었습니다.']);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
          }
        });
      };
      this.confirm({
        show: true,
        func,
        msg: '삭제시 복구할 수 없습니다.<br/>정말 삭제할까요?',
        btnText: '삭제',
        color: 'error',
      });
    },
    getEstRefundAmount(date) {
      const localDate = time.makeLocalTime(date, 'date');
      const saleDate = time.moment(
        time.makeLocalTime(this.item.originSale.serviceStart, 'date'),
      ).format('YYYY-MM-DD');
      const endDate = time.moment(saleDate)
        .add(this.item.originSale.productTerm, 'M').format('YYYY-MM-DD');
      const serviceTerm = time.moment(endDate).diff(saleDate, 'days');
      const diffDays = time.moment(localDate).diff(saleDate, 'days') >= 0
        ? time.moment(localDate).diff(saleDate, 'days') : 0;
      const dayPrice = Math.floor(Number(this.item.originSale.amount) / serviceTerm);
      const penaltyPrice = Math.floor(Number(this.item.originSale.amount) * 0.1);
      const refundPrice = (Number(this.item.originSale.amount) / serviceTerm)
        * (serviceTerm - diffDays) - penaltyPrice;
      return {
        saleDate, // 매출일
        endDate, // 일할계산종료일
        serviceTerm, // 일할계산일수
        date: localDate, // 환불일
        diffDays, // 서비스이용일수
        remainDays: serviceTerm - diffDays > 0 ? serviceTerm - diffDays : 0, // 잔여일수
        dayPrice, // 일할계산합계(1일)
        penaltyPrice,
        refundPrice: refundPrice > 0 ? Math.round(refundPrice) : 0, // 일할계산합계(잔여합계)
      };
    },
    setServiceEndDate() {
      const endDate = time.moment(this.serviceStartDate)
        .add(this.item.productTerm + this.item.productFreeTerm, 'M').format('YYYY-MM-DD');
      this.serviceEndDate = endDate;
    },
    confirmDlgOpen(toStatus = null) {
      const item = { ...this.item };
      const func = () => {
        if (toStatus === 1) {
          this.confirmDate = this.time.moment().format('YYYY-MM-DD');
          this.serviceStartDate = this.time.moment().format('YYYY-MM-DD');
          this.serviceEndDate = this.time.moment().add(
            item.productTerm + item.productFreeTerm, 'M',
          ).format('YYYY-MM-DD');
        } else {
          this.confirmDate = this.time.makeLocalTime(item.date, 'date');
        }
        this.confirmDlg = {
          show: true,
          toStatus,
        };
        this.progress(false);
      };
      if (item.type === 0) {
        func();
      } else if (item.type === 1) {
        this.progress(true);
        this.$socket.emit('sales.get', { no: item.origin }, (resp) => {
          if (resp.result === 'success') {
            const originStatus = resp.item.status;
            if (originStatus !== 1 && toStatus === 1) {
              this.confirm({
                show: true,
                msg: `기존 매출건이 승인되지 않아 처리할 수 없습니다.<br/>(기존매출번호: ${item.origin})`,
                color: 'primary',
                noneCallback: true,
              });
              this.progress(false);
            } else {
              func();
            }
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
          }
        });
      } else {
        this.alert(['error', '오류가 발생했습니다.']);
      }
    },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.sales.menu.sales.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
    getProductRooms(no) {
      this.currentRooms = null;
      this.$socket.emit('rooms.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [true],
        filters: [{
          condition: 'eq',
          column: 'disabled',
          value: 0,
        }, {
          where: 'and',
          condition: 'eq',
          column: 'productNo',
          value: no,
        }],
      }, (resp) => {
        if (resp.result === 'success') {
          this.currentRooms = resp.items;
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val === true) {
          this.getGroupList();
          this.getStaffsList();
        }
      },
    },
    item: {
      deep: true,
      handler(val) {
        if (val.disabled === 1) {
          this.alert(['error', '삭제된 매출건입니다.']);
        }
      },
    },
  },
};
</script>
<style lang="scss">
.width-fix-dialog {
  min-width: 1000px;
}
</style>
<style scoped lang="scss">
.pay-form-wrap {
  position: relative;
}
.pay-form {
  padding: 10px;
  min-width: 640px;
  max-width: 640px;
  position: absolute;
  right: 0px;
  z-index: 1;
  top: -100px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  .v-list {
    max-height: 100px;
    overflow: auto;
  }
  &.search-picker-wide {
    min-width: 300px;
    max-width: 800px;
  }
  .input-wrap {
    display: flex;
    align-items: center;
    .text-input-wrap {
      width: 200px;
    }
  }
  .pay-input-wrap {
    .text-input-wrap {
      width: 100%;
    }
  }
}
.width-fix-dialog {
  min-width: 1500px;
}
.content-card {
  border-radius: 0;
  .sales-number {
    margin: 0;
    padding-left: 10px;
    font-size: 0.8rem;
    color: #888;
  }
}
.title-bar {
  padding: 4px 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  .title-info {
    display: flex;
    align-items: center;
    .item-wrap {
      display: flex;
      align-items: center;
      & * {
        flex-shrink: 0;
      }
    }
  }
  .label-wrap {
    display: flex;
    align-items: center;
  }
}

.text-item {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  .item-tit {
    font-size: 0.9rem;
    color: #262626;
    font-weight: bolder;
    margin-right: 5px;
  }
  .item-span {
    font-size: 0.9rem;
    &.date {
      font-size: 0.8rem;
      margin-left: 4px;
      margin-bottom: -2px;
      color: #888;
    }
  }
}
.pay-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  .confirm-chip {
    min-width: 60px;
    display: flex;
    justify-content: center;
  }
  .pay-info {
    display: flex;
    align-items: center;
    .pay-text {
      color: #262626;
      font-size: 0.9rem;
    }
  }
  & + .pay-row {
    border-top: 1px solid #eee;
    padding-top: 10px;
  }
}
.fix-width {
  min-width: 950px;
}
.item-row {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  & * {
    flex-shrink: 0;
  }
  &.border {
    padding-top: 8px;
    border-top: 1px dashed #ccc;
  }
  &.total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .total-data {
      &.bold {
        font-weight: bold;
        font-size: 1rem;
      }
      font-size: 0.9rem;
    }
  }
  & + .item-row {
    margin-top: 10px;
  }
}
.content-tit {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.bottom-wrap {
  display: flex;
  padding: 20px;
  padding-top: 10px;
  .memo-wrap {
    width: 100%;
    .chip-max-width {
      max-width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .memo-write-wrap {
      background-color: #f2f2f2;
      padding: 10px;
      border-radius: 10px;
      margin-right: 2px;
      display: flex;
      align-items: flex-end;
      .left-wrap {
        width: 100%;
        .receiver-file-wrap {
          padding: 0px 4px;
          margin-bottom: 8px;
          width: 100%;
          display: flex;
          align-items: center;
          .name {
            font-size: 0.9rem;
          }
        }
      }
      .btn-wrap {
        margin-left: 8px;
        width: 50px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: center;
        position: relative;
        .staffs-picker {
          width: 250px;
          max-width: 250px;
          position: absolute;
          left: 50px;
          z-index: 1;
          top: 0px;
          box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
            0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
          &.b-type {
            right: 50px;
            left: unset;
          }
          .v-list {
            max-height: 100px;
            overflow: auto;
          }
        }
      }
      .area {
        width: 100%;
        border: 1px solid #ccc;
        background-color: #fff;
        &:focus {
          outline: none;
        }
      }
    }
    .memo-list {
      height: 150px;
      overflow: auto;
      padding-right: 2px;
      padding: 10px;
      background-color: #f2f2f2;
      border: 1px solid #ccc;
      & + .memo-write-wrap {
        margin-top: 10px;
      }
      .memo {
        background-color: #fff;
        padding: 0px;
        border: 1px solid #ccc;
        border-radius: 10px;
        & + .memo {
          margin-top: 10px;
        }
        .memo-top {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          // border-bottom:1px solid #ccc;
          padding: 6px;
          padding-bottom: 0;
          .text {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            .date {
              font-size: 0.8rem;
              color: #333;
            }
          }
        }
        .memo-body {
          font-size: 0.8rem;
          color: #000;
          padding: 6px;
          margin: 0;
          white-space: pre-wrap;
        }
        .memo-file {
          display: flex;
          justify-content: flex-end;
          padding: 6px;
        }
      }
    }
    & + .visit-wrap {
      margin-left: 15px;
    }
  }
  .visit-wrap {
    flex-shrink: 0;
    .visit-msg {
      padding: 10px;
      background-color: #f2f2f2;
      font-size: 0.9rem;
      border-radius: 10px;
      min-width: 350px;
    }
    .visit {
      min-width: 350px;
      background-color: #f2f2f2;
      padding: 10px;
      border-radius: 0px;
      height: 148px;
      overflow: auto;
      border: 1px solid #ccc;
      &.on {
        height: 308px;
      }
      p {
        margin: 0px;
        width: 100%;
        justify-content: space-between;
        font-size: 0.8rem;
        display: flex;
        span {
          margin-left: 8px;
        }
        & + p {
          margin-top: 2px;
        }
      }
    }
  }
}
.content-wrap {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-right: 0;
  border-left: 0;
  overflow-x: hidden;
  height: 100%;
  .content-row {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    & + .content-row {
      margin-top: 6px;
      padding-top: 15px;
      border-top: 1px dashed #ccc;
    }
  }
}
.file-input {
  display: none;
}
.options {
  font-size: 0.9rem;
  padding: 0px 20px;
  min-height: 34px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.update-picker-wrap {
  position: relative;
  .staffs-picker {
    width: 250px;
    max-width: 250px;
    position: absolute;
    left: 30px;
    z-index: 1;
    top: 0px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    &.b-type {
      right: 50px;
      left: unset;
    }
    .v-list {
      max-height: 100px;
      overflow: auto;
    }
  }
  .discount-picker {
    padding: 10px;
    position: absolute;
    left: 30px;
    z-index: 1;
    top: 0px;
    max-width: 300px;
    width: 300px;
    & * {
      flex-shrink: 1;
    }
  }
}
.date-picker-top {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 15px;
  .tit {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: #262626;
    display: flex;
    align-items: center;
    i {
      margin-left: 2px;
    }
  }
}
.date-picker-content {
  border-radius: 0;
  .total-span {
    font-size: 0.85rem;
    font-weight: 500;
  }
}
.confirm-picker-body {
  background-color: #fff;
  padding: 10px;
  padding-top: 0px;
  .section {
    position: relative;
    padding: 15px;
    display: flex;
    align-items: center;
    color: #333;
    justify-content: space-between;
    & + .section,
    & + .section-wrap {
      border-top: 1px dashed #ccc;
    }
    .tit {
      font-size: 0.9rem;
      font-weight: 500;
      margin: 0;
    }
    .sub {
      font-weight: 600;
      font-size: 0.9rem;
      display: flex;
      align-items: center;
    }
  }
}
.gray--text {
  color: #888;
}
.item-span {
  font-size: 0.9rem;
}
</style>
