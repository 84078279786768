const payloadUnit = 1500;

function combineBuffer(buffers) {
  const size = buffers.reduce((total, buf) => (total + buf.byteLength), 0);
  const bytes = new Uint8Array(size);

  let offset = 0;
  buffers.forEach((buf) => {
    bytes.set(new Uint8Array(buf), offset);
    offset += buf.byteLength;
  });

  return bytes;
}

function divideBuffer(buf) {
  const data = [];
  const last = buf.byteLength;

  for (let idx = 0; idx < last; idx += payloadUnit) {
    data.push(buf.slice(idx, idx + payloadUnit));
  }

  return data;
}

function downloadFile(bytes, filename) {
  const blob = new Blob([bytes]);
  const url = URL.createObjectURL(blob);

  const tag = document.createElement('a');
  tag.href = url;
  tag.download = filename;

  document.body.appendChild(tag);

  tag.style.display = 'none';
  tag.click();
  tag.remove();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 1000);
}

export default {
  async jsonToBuffers(data) {
    const textEncoder = new TextEncoder();
    const buf = textEncoder.encode(data).buffer;
    return divideBuffer(buf);
  },
  async buffersToJson(buffers) {
    const buf = combineBuffer(buffers);
    const textDecoder = new TextDecoder('utf-8');
    const arrayBuf = Uint8Array.from(buf);
    return textDecoder.decode(arrayBuf);
  },
  async readFileToPayload(file) {
    const data = [];
    const buf = await file.arrayBuffer();
    const last = buf.byteLength;

    for (let idx = 0; idx < last; idx += payloadUnit) {
      data.push(buf.slice(idx, idx + payloadUnit));
    }

    return {
      name: file.name,
      size: buf.byteLength,
      data,
    };
  },
  async writeFileFromPayload(payload) {
    const data = combineBuffer(payload.data);
    downloadFile(data, payload.name);
  },
  byteTrans(x) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0;
    let n = parseInt(x, 10) || 0;
    while (n >= 1024 && (l + 1)) {
      n /= 1024;
      l += 1;
    }
    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
  },
  getByteLength(str) {
    let len = 0;
    let idx = 0;
    let c = str.charCodeAt(0);
    for (idx = 1; !Number.isNaN(c); idx += 1) {
      // eslint-disable-next-line
      len += (c >> 7) ? 2 : 1;
      c = str.charCodeAt(idx);
    }
    return len;
  },
};
