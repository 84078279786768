<template>
  <div class="notification-wrap"
    :class="notificationModal === true && notifications.length > 0?'on':''"
  >
    <v-card
      width="500"
      class="mx-auto overflow-y-auto"
      max-height="400"
      outlined
      elevation="3"
    >
    <div class="info-wrap d-flex justify-space-between align-center">
      <p class="info-text">
        신규 알림
      </p>
      <v-btn
        small
        @click="notificationModalCng(false);"
        depressed
      >
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
      <v-list
        two-line
      >
        <v-list-item
          v-for="item in notifications"
          :key="item.no"
        >
          <v-list-item-content>
            <v-flex>
              <v-chip small outlined label class="pl-2 pr-2">
                {{ comm.transNotifictionType(item.type) }}
              </v-chip>
            </v-flex>
            <!-- <v-list-item-action-text v-text="item.sendName"></v-list-item-action-text> -->
            <v-list-item-title v-text="item.content"></v-list-item-title>
            <v-list-item-action-text
              v-text="`
                ${comm.getGroupFullPath(groups, item.senderGroupNo)}${item.senderName},
                ${time.makeLocalTime(item.created, 'sec')}
              `"
            >
            </v-list-item-action-text>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip top color="black">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="enterNotification({no:item.no, redirect:item.redirect})"
                >
                  <v-icon color="grey lighten-1">mdi-arrow-right-circle</v-icon>
                </v-btn>
              </template>
              <span>바로가기</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import comm from '@/util/comm';
import time from '@/util/time';

export default {
  name: 'DlgNotification',
  data: () => ({
    unReadNotifications: () => this.notifications.filter((r) => r.read === 0),
    comm,
    time,
    groups: [],
  }),
  computed: {
    ...mapGetters({
      notifications: 'notification/notifications',
      notificationModal: 'notification/notificationModal',
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    ...mapActions({
      transNotifictionType: 'notification/transNotifictionType',
      enterNotification: 'notification/enterNotification',
      alert: 'dialog/alert',
    }),
    ...mapMutations({
      notificationModalCng: 'notification/notificationModal',
      notificationsCng: 'notification/notifications',
    }),
    getGroupList() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getGroupList();
        }
      },
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
  .notification-wrap {
    z-index: 500;
    position: fixed;
    right: 20px;
    bottom: -500px;
    transition: .5s ease-in-out;
    opacity: 0;
    &.on {
      bottom: 20px;
      opacity: 1;
    }
    .info-wrap {
      background-color: #f2f2f2;
      .info-text {
        margin: 0;
        font-size: .8rem;
        font-weight: 500;
        padding:0px 15px;
        display: flex;
        align-items: center;
        line-height: 10px;
      }
    }
  }
</style>
