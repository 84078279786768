/* eslint-disable no-console */

import { register } from 'register-service-worker';
import store from '@/store';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
      store.dispatch('dialog/progress', true, { root: true });
      store.dispatch('dialog/alert', ['info', '새로운 업데이트가 있습니다. 잠시 후 재시작 됩니다.', false], { root: true });
      setTimeout(() => {
        window.location.reload(true);
      }, 3000);
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
